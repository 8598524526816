import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../api";
import SignedOutHeader from "../components/SignedOutHeader";
import Button from "../components/Button";
import PasswordInput from "../components/PasswordInput";
import { CreateUserFromRequestTokenRequestBody, IUserRequestResponse } from "@dock-technologies/common";

export default function ResetPassword() {
  const navigate = useNavigate();
  const { token, orgId } = useParams();

  const [userRequestData, setUserRequestData] = useState<IUserRequestResponse | null>(null);
  const [error, setError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  useEffect(() => {
    api
      .fetch("/user/api=getPendingUserRequest", { method: "POST", body: JSON.stringify({ token, orgId }) })
      .then((res) => res.json())
      .then((data: { obj: IUserRequestResponse }) => {
        setUserRequestData(data.obj);
      });
  }, [orgId, token]);

  const onSignUpClicked = useCallback(() => {
    if (!newPassword || !confirmNewPassword) {
      setError("Both fields are required");
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setError("Passwords must match");
      return;
    }
    if (userRequestData == null) {
      setError("No user data found");
      return;
    }

    const createUserBody: CreateUserFromRequestTokenRequestBody = {
      orgId,
      token,
      user: {
        email: userRequestData.email,
        firstName: userRequestData.firstName,
        lastName: userRequestData.lastName,
        password: newPassword,
      },
    };

    api
      .fetch("/user/api=createUserFromRequestToken", { method: "POST", body: JSON.stringify(createUserBody) })
      .then(() => {
        navigate("/login");
      });
  }, [confirmNewPassword, navigate, newPassword, orgId, token, userRequestData]);

  if (userRequestData == null) {
    return <></>;
  }

  return (
    <div className="mb-32 mt-16 flex flex-col">
      <SignedOutHeader />
      <div className="mt-5 flex flex-col rounded-xl bg-white p-8 shadow-md sm:mx-[33%]">
        <div className="flex-1 text-center">
          <h2 className="text-wb text-primary-900">ACCOUNT SIGN UP</h2>
        </div>
        <div className="flex-1 text-center">
          <label className="text-sm text-primary-900">
            {userRequestData.firstName} {userRequestData.lastName},<br />
            You have been invited to {userRequestData.orgName}'s INSIGHT TIMEBAND account. Please enter a password to
            complete account signup.`
          </label>
        </div>
        {error && (
          <div className="flex-1 text-center">
            <h2 className="mt-2 text-lg text-error-500">{error}</h2>
          </div>
        )}
        <div className="mt-5 flex-1">
          <div className="flex flex-col">
            <PasswordInput
              label="NEW PASSWORD"
              password={newPassword}
              onPasswordChanged={(newP) => {
                setError("");
                setNewPassword(newP);
              }}
            />
          </div>
        </div>
        <div className="mt-5 flex-1">
          <div className="flex flex-col">
            <PasswordInput
              label="REENTER PASSWORD"
              password={confirmNewPassword}
              onPasswordChanged={(newP) => {
                setError("");
                setConfirmNewPassword(newP);
              }}
            />
          </div>
        </div>
        <div className="mt-5 flex-1">
          <Button
            className="text-bold w-full bg-primary-500 text-white hover:bg-primary-700"
            text="SIGN UP"
            onClick={onSignUpClicked}
          />
        </div>
      </div>
    </div>
  );
}
