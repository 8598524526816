import { IUser } from "@dock-technologies/common";
import { jwtDecode } from "jwt-decode";

export interface LoggedInUser {
  user: IUser;
  isAdmin: boolean;
  isSuperAdmin: boolean;
}

export function getLoggedInUser(): LoggedInUser {
  const token = localStorage.getItem("token");
  if (!token) {
    return null;
  }
  return decodeUserToken(token);
}

export function decodeUserToken(token: string) {
  if (!token) {
    return null;
  }
  return jwtDecode<LoggedInUser>(token);
}

export function getUniqueUsers(users: (IUser | null | undefined)[]): IUser[] {
  const nonNulls = users.filter((u): u is NonNullable<typeof u> => u != null);
  const usersById = nonNulls.reduce<Record<string, IUser>>((acc, curr) => {
    acc[curr._id] = curr;
    return acc;
  }, {});
  return Object.values(usersById);
}
