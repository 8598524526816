export default function LoggedOutNavbar() {
  return (
    <div className="navbar flex flex-row bg-white px-4 py-3 sm:px-16">
      <div className="flex-1">
        <a href="https://docktechnologies.com">
          <img className="w-32" src="/dock-logo.png" />
        </a>
      </div>
    </div>
  );
}
