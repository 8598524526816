import { DEVICE_TYPE_EXPIRATION_TIME_MAP } from "./constants";
import {
  dayjs,
  DEVICE_DAYS_TIMEZONE,
  ONE_DAY_SECONDS,
  secondsToDays,
} from "./date";
import { DeviceType, ExpirationState } from "./enums";
import { ITimerPopulated } from "./models";
import { sortStoredTimes } from "./step";

/** 4 days is default expiration time when deviceType is not set */
export const DEFAULT_EXPIRATION_TIME_SECONDS = 4 * 24 * 60 * 60;

/**
 * Returns the {@link ExpirationState} based on the provided `deviceType` and `runtimeSeconds`
 *
 * At the moment this returns {@link ExpirationState.NEED_FINALIZATION} if 0 runtime is provided.
 */
export function getExpirationState(
  deviceType: DeviceType,
  runtimeSeconds: number,
  active: boolean,
  archived: boolean,
): ExpirationState {
  if (archived) {
    return ExpirationState.FINALIZED;
  } else if (!active) {
    return ExpirationState.NEED_FINALIZATION;
  }

  const expirationTimeSeconds = deviceType
    ? DEVICE_TYPE_EXPIRATION_TIME_MAP[deviceType]
    : DEFAULT_EXPIRATION_TIME_SECONDS;
  if (runtimeSeconds >= expirationTimeSeconds) {
    return ExpirationState.PAST_EXPIRATION;
  }
  if ((expirationTimeSeconds - runtimeSeconds) <= ONE_DAY_SECONDS) {
    return ExpirationState.LESS_THAN_1_DAY;
  } else {
    return ExpirationState.GREATER_THAN_1_DAY;
  }
}

export function getExpirationSeconds(deviceType?: DeviceType) {
  const expirationSeconds = deviceType
    ? DEVICE_TYPE_EXPIRATION_TIME_MAP[deviceType]
    : DEFAULT_EXPIRATION_TIME_SECONDS;
  return expirationSeconds;
}

export function getExpirationDays(deviceType?: DeviceType) {
  const seconds = getExpirationSeconds(deviceType);
  return secondsToDays(seconds, "up");
}

export function getPostExpiration(expirationDays: number, deviceDay: number) {
  return Math.floor((deviceDay - expirationDays) * 10) / 10;
}

export function getSecondsUntilExpiration(
  runtime: number,
  deviceType?: DeviceType,
) {
  const expirationSeconds = getExpirationSeconds(deviceType);
  return expirationSeconds - runtime;
}

export function getDeviceDays(timer: ITimerPopulated) {
  const storedTimes = timer.storedTimes ?? [];
  if (storedTimes.length === 0) {
    return 0;
  }

  const sortedTimes = sortStoredTimes(storedTimes);
  const first = sortedTimes.length < 2
    ? timer.startTime
    : sortedTimes[0].startTime;
  const last = timer.active
    ? new Date()
    : sortedTimes[sortedTimes.length - 1].startTime;

  const start = dayjs(first).startOf("day").tz(
    DEVICE_DAYS_TIMEZONE,
  );

  // We add 1 millisecond here since end of day is off by 1 millisecond to get continuous
  // day difference
  const end = dayjs(last).endOf("day").add(1, "millisecond").tz(
    DEVICE_DAYS_TIMEZONE,
  );

  const diff = dayjs(end).diff(start);
  const duration = dayjs.duration(diff);

  return duration.days();
}
