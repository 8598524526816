import Papa from "papaparse";

import {
  ExpirationState,
  FilterOption,
  ITimerPopulated,
  QuickSelectFilter,
  RuntimeDisplay,
  SortOption,
  dayjs,
} from "@dock-technologies/common";
import RuntimeDisplayToggle from "../components/RuntimeDisplayToggle";
import SelectListbox from "../components/SelectListbox";
import SummaryRow from "../components/SummaryRow";
import { useState, useCallback, useEffect, useMemo } from "react";
import { fetchHistoryTimers, fetchTimerSummary } from "../api";
import { SUMMARY_REFRESH_INTERVAL_MS, TIMEBAND_REFRESH_INTERVAL_MS } from "../utilities/refresh";
import TimebandHistoryTable, { mapTimersToTableRows } from "../components/TimebandHistoryTable";
import { applyHistoryFiltersAndSort } from "../utilities/filter-sort";
import HistoryFilters from "../components/HistoryFilters";
import SummaryStatistics from "../components/SummaryStatistics";
import { generateTimerStatistics } from "../utilities/timer";
import { isMobile } from "../utilities/mobile";
import Sidebar from "../components/Sidebar";

const SORT_OPTIONS = Object.values(SortOption);
const FILTER_OPTIONS = Object.values(FilterOption);

let fetchTimersInterval: ReturnType<typeof setInterval> | null = null;
let fetchSummaryInterval: ReturnType<typeof setInterval> | null = null;

function download(filename: string, text: string) {
  const element = document.createElement("a");
  element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export default function TimebandsHistory() {
  const [historyTimers, setHistoryTimers] = useState<ITimerPopulated[]>([]);

  // const [timebandView, setTimebandView] = useState(TimebandView.TABLE);
  const [runtimeDisplay, setRuntimeDisplay] = useState(RuntimeDisplay.D_H);
  const [sortOption, setSortOption] = useState(SortOption.NONE_SELECTED);
  const [filterOptions, setFilterOptions] = useState([FilterOption.ALL]);

  const [fromFilter, setFromFilter] = useState<Date | null>(null);
  const [toFilter, setToFilter] = useState(null);
  const [quickSelectFilter, setQuickSelectFilter] = useState(QuickSelectFilter.NONE);

  const [timersSummary, setTimersSummary] = useState<Record<ExpirationState, number> | null>(null);

  const onSortOptionChanged = useCallback((options: SortOption[]) => {
    const option = options[0];
    setSortOption(option);
  }, []);

  const onFilterOptionChanged = useCallback((options: FilterOption[]) => {
    // We want to toggle on/off the `All` filter on certain occasions
    // The most recent option selected is the last in `options` array
    const indexOfAll = options.findIndex((o) => o === FilterOption.ALL);
    if (indexOfAll === options.length - 1) {
      // Most recently selected `All`, so set to only `All`
      setFilterOptions([FilterOption.ALL]);
    } else if (indexOfAll !== -1) {
      // Most recent was not `All`, but filter contains `All`, so we need to remove `All`
      options.splice(indexOfAll, 1);
      setFilterOptions(options);
    } else {
      setFilterOptions(options);
    }
  }, []);

  // const onTimebandViewChanged = useCallback((view: TimebandView) => {
  //   setTimebandView(view);
  // }, []);

  const onRuntimeDisplayChanged = useCallback((runtime: RuntimeDisplay) => {
    setRuntimeDisplay(runtime);
  }, []);

  const onQuickSelectFilterChanged = useCallback((filter: QuickSelectFilter) => {
    let to: dayjs.Dayjs | null = null;
    let from: Date | null = null;
    switch (filter) {
      case QuickSelectFilter.NONE: {
        to = null;
        break;
      }
      case QuickSelectFilter.LAST_30_DAYS: {
        to = dayjs();
        from = to.subtract(30, "day").toDate();
        break;
      }
      case QuickSelectFilter.LAST_60_DAYS: {
        to = dayjs();
        from = to.subtract(60, "day").toDate();
        break;
      }
      case QuickSelectFilter.LAST_90_DAYS: {
        to = dayjs();
        from = to.subtract(90, "day").toDate();
        break;
      }
    }
    setToFilter(to ? to.toDate() : null);
    setFromFilter(from);
    setQuickSelectFilter(filter);
  }, []);

  const onFromFilterChanged = useCallback((from: Date) => {
    setQuickSelectFilter(QuickSelectFilter.NONE);
    setFromFilter(from);
  }, []);

  const onToFilterChanged = useCallback((to: Date) => {
    setQuickSelectFilter(QuickSelectFilter.NONE);
    setToFilter(to);
  }, []);

  useEffect(() => {
    if (fetchTimersInterval) {
      clearInterval(fetchTimersInterval);
      fetchTimersInterval = null;
    }
    fetchTimersInterval = setInterval(() => {
      fetchHistoryTimers().then((data) => setHistoryTimers(data));
    }, TIMEBAND_REFRESH_INTERVAL_MS);
    fetchHistoryTimers().then((data) => setHistoryTimers(data));

    return () => {
      if (fetchTimersInterval) {
        clearInterval(fetchTimersInterval);
      }
    };
  }, []);

  useEffect(() => {
    if (fetchSummaryInterval) {
      clearInterval(fetchSummaryInterval);
      fetchSummaryInterval = null;
    }
    fetchSummaryInterval = setInterval(() => {
      fetchTimerSummary().then((data) => setTimersSummary(data));
    }, SUMMARY_REFRESH_INTERVAL_MS);
    fetchTimerSummary().then((data) => setTimersSummary(data));

    return () => {
      if (fetchSummaryInterval) {
        clearInterval(fetchSummaryInterval);
      }
    };
  }, []);

  const filteredSortedTimers = useMemo(() => {
    return applyHistoryFiltersAndSort(historyTimers, filterOptions, sortOption, fromFilter, toFilter);
  }, [historyTimers, filterOptions, sortOption, fromFilter, toFilter]);

  const summaryStatistics = useMemo(() => {
    return generateTimerStatistics(filteredSortedTimers);
  }, [filteredSortedTimers]);

  const onExportToCsvClicked = useCallback(() => {
    if (filteredSortedTimers.length > 0) {
      const mapped = mapTimersToTableRows(filteredSortedTimers);
      const csv = Papa.unparse(mapped);
      const now = new Date().getTime();

      download(`timer-history-export-${now}.csv`, csv);
    }
  }, [filteredSortedTimers]);

  return (
    <div className="mt-5 flex flex-col">
      <div className="flex-1">
        <div className="flex flex-col sm:flex-row">
          {isMobile() && (
            <div className="mb-2 ml-2 mr-2 flex flex-none basis-6/12 flex-row">
              <Sidebar />
            </div>
          )}
          <div className="flex flex-1 flex-row sm:basis-4/12">
            <div className="ml-4 mr-2 mt-2 flex-none align-middle text-xs text-white sm:ml-0 sm:mt-3 sm:text-label">
              SORT
            </div>
            <div className="flex-1">
              <SelectListbox
                options={SORT_OPTIONS}
                selectedOptions={[sortOption]}
                onOptionChanged={onSortOptionChanged}
              />
            </div>
          </div>
          <div className="ml-4 flex flex-1 flex-row sm:ml-10 sm:basis-4/12">
            <div className="mr-2 mt-2 flex-none text-xs text-white sm:mt-3 sm:text-label">FILTER</div>
            <div className="max-w-96 flex-1">
              <SelectListbox
                options={FILTER_OPTIONS}
                selectedOptions={filterOptions}
                onOptionChanged={onFilterOptionChanged}
                multiple
              />
            </div>
          </div>
          <div className="ml-10 flex flex-none basis-2/12 flex-row">
            {/* <div className="flex-none text-label text-white mt-3 mr-2">VIEW</div>
            <div className="flex-1">
              <TimebandViewToggle view={timebandView} onTimebandViewChanged={onTimebandViewChanged} />
            </div> */}
          </div>
          <div className="ml-4 flex flex-none flex-row sm:ml-0 sm:basis-2/12">
            <div className="mr-2 mt-3 flex-none text-xs text-white sm:text-label">RUNTIME</div>
            <div className="flex-1">
              <RuntimeDisplayToggle runtimeDisplay={runtimeDisplay} onRuntimeDisplayChanged={onRuntimeDisplayChanged} />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 flex-1">{timersSummary && <SummaryRow timersSummary={timersSummary} />}</div>
      <div className="flex-1 bg-white">
        <HistoryFilters
          from={fromFilter}
          onFromChanged={onFromFilterChanged}
          to={toFilter}
          onToChanged={onToFilterChanged}
          quickSelectFilter={quickSelectFilter}
          onQuickSelectFilterChanged={onQuickSelectFilterChanged}
        />
      </div>
      <div className="flex-1 bg-white p-4">
        <SummaryStatistics {...summaryStatistics} onExportToCsvClicked={onExportToCsvClicked} />
      </div>
      <div className="flex-1 bg-white p-4">
        <TimebandHistoryTable timers={filteredSortedTimers} runtimeDisplay={runtimeDisplay} />
      </div>
    </div>
  );
}
