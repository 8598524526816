export interface InputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  id: string;
  labelClassName?: string;
  label?: string;
  error?: string;
}

export default function Input(props: InputProps) {
  return (
    <>
      <label htmlFor={props.id} className={props.labelClassName}>
        {props.label}
      </label>
      <div>
        <input id={props.id} {...props} />
      </div>
      {props.error && (
        <p className="mt-2 text-sm text-error-500" id={`${props.id}-error`}>
          {props.error}
        </p>
      )}
    </>
  );
}
