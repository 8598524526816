export enum UserRole {
  USER = "user",
  SUPER_ADMIN = "superAdmin",
}

export enum OrgType {
  DEMO = "Demo",
  TRIAL = "Trial",
  CLINICAL = "Clinical",
}

export enum DeviceType {
  INTERNAL_JUGULAR_CVC = "Internal jugular, CVC",
  INTERNAL_JUGULAR_QUINTON = "Internal jugular, Quinton",
  INTERNAL_JUGULAR_ZOLL = "Internal jugular, Zoll",
  FEMORAL_CVC = "Femoral, CVC",
  FEMORAL_QUINTON = "Femoral, Quinton",
  FEMORAL_ZOLL = "Femoral, Zoll",
  UPPER_EXTREMITY_PICC = "Upper Extremity, PICC",
}

export enum DeviceTypeDirection {
  LEFT = "Left",
  RIGHT = "Right",
}

export enum ExpirationState {
  GREATER_THAN_1_DAY = "EXPIRING IN >1.0 Days",
  LESS_THAN_1_DAY = "EXPIRING IN <1.0 Day",
  PAST_EXPIRATION = "PAST EXPIRATION",
  NEED_FINALIZATION = "NEED FINALIZATION",
  FINALIZED = "FINALIZED",
}

export enum TimebandView {
  TABLE = "Table",
  CARD = "Card",
}

export enum RuntimeDisplay {
  D = "D",
  D_H = "D,H",
  H = "H",
}

export enum FilterOption {
  ALL = "All",
  ONLY_ACTIVE = "Only Active",
  ONLY_INACTIVE = "Only Inactive",
  PAST_EXPIRATION = "Past Expiration",
  LESS_THAN_1_DAY = "<1.0 Day of Expiration",
  GREATER_THAN_1_DAY = ">1.0 Day of expiration",
  ALL_INTERNAL_JUGULAR = "All Internal Jugular",
  ALL_FEMORAL = "All Femoral",
  ALL_UPPER_EXTREMITY = "All Upper Extremity",
  ONLY_ICU = "Only ICU",
  ONLY_STEPDOWN = "Only Stepdown",
}

export enum SortOption {
  NONE_SELECTED = "None Selected",
  EXPIRATION_CLOSEST = "Expiration: Closest",
  EXPIRATION_FARTHEST = "Expiration: Farthest",
  ROOM_NUM_SMALLEST = "Room #: Smallest",
  ROOM_NUM_LARGEST = "Room #: Largest",
  RUNTIME_LONGEST = "Runtime: Longest",
  RUNTIME_SHORTEST = "Runtime: Shortest",
  START_TIME_YOUNGEST = "Start Time: Youngest",
  START_TIME_OLDEST = "Start Time: Oldest",
  STEPS_NUM_MOST = "Steps #: Most",
  STEPS_NUM_LEAST = "Steps #: Least",
}

export enum LocationType {
  STEPDOWN = "Stepdown",
  ICU = "ICU",
}

export enum StoredTimeStep {
  PLACEMENT = "Placement",
  TIMEBAND_STARTED_NOT_PLACEMENT = "Timeband started, not placement",
  VISUAL_CHECK = "Visual check",
  CLEANING = "Cleaning",
  TUBING_FLUSH = "Tubing flush",
  DRESSING_CHANGE = "Dressing change",
  DISCONTINUED = "Discontinued",
  OTHER_SEE_NOTE = "Other: see note",
}

export enum ModuleType {
  ACUTE = "Acute",
  CVC = "CVC",
}

export function isValidModuleType(
  maybeModuleType: string,
): maybeModuleType is ModuleType {
  return Object.values(ModuleType).includes(maybeModuleType);
}

export enum QuickSelectFilter {
  NONE = "None",
  LAST_30_DAYS = "Last 30 days",
  LAST_60_DAYS = "Last 60 days",
  LAST_90_DAYS = "Last 90 days",
}
