import { INPUT_DATE_FORMAT, dayjs } from "@dock-technologies/common";
import Label from "./Label";
import QuickSelectToggle, { QuickSelectToggleProps } from "./QuickSelectToggle";

export interface HistoryFilterProps extends QuickSelectToggleProps {
  from: Date | null;
  onFromChanged: (from: Date) => void;
  to: Date | null;
  onToChanged: (to: Date) => void;
}

export default function HistoryFilters(props: HistoryFilterProps) {
  return (
    <div className="flex flex-col sm:flex-row mt-4">
      <div className="flex-none mt-1 ml-4 sm:ml-12">
        <Label className="text-primary-900 mr-2">QUICK FILTER</Label>
      </div>
      <div className="flex-1">
        <QuickSelectToggle {...props} />
      </div>
      <div className="flex-none mt-1 ml-4 sm:ml-12">
        <Label className="text-primary-900 mr-2">RANGE</Label>
      </div>
      <div className="flex-1">
        <input
          className="rounded px-2 py-1 w-40 border border-neutral-500"
          type="date"
          value={dayjs(props.from).format(INPUT_DATE_FORMAT)}
          onChange={(e) => props.onFromChanged(dayjs(e.target.value).toDate())}
        />
        <label className="text-primary-900 mx-2">-</label>
        <input
          className="rounded px-2 py-1 w-40 border border-neutral-500"
          type="date"
          value={dayjs(props.to).format(INPUT_DATE_FORMAT)}
          onChange={(e) => props.onToChanged(dayjs(e.target.value).toDate())}
        />
      </div>
    </div>
  );
}
