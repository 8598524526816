import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
  ITimerNotePopulated,
  ITimerPopulated,
  dayjs,
} from "@dock-technologies/common";
import { useCallback, useEffect, useMemo, useState } from "react";
import { formatUserInitials } from "../utilities/format";
import { ColumnDefinition } from "../types";
import Button from "./Button";
import { LoggedInUser, getLoggedInUser } from "../utilities/user";
import { api } from "../api";

interface PendingTimerNote extends ITimerNotePopulated {
  isPending?: boolean;
}

type MappedNote = ReturnType<typeof mapTimerNotesToTableRows>[0];

export function mapTimerNotesToTableRows(notes: ITimerNotePopulated[]) {
  return notes
    .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
    .map((note) => {
      return {
        _id: note._id,
        date: note.createdAt,
        time: note.createdAt,
        note: note.note,
        user: note.createdBy ? formatUserInitials(note.createdBy) : "N/A",
        isPending: "isPending" in note && note.isPending,
      };
    });
}

export function getDefaultPendingNote(timer: ITimerPopulated): PendingTimerNote {
  const now = new Date();

  const { user } = getLoggedInUser();
  return {
    _id: "",
    timerId: timer._id,
    modifiedAt: now,
    createdAt: now,
    note: "",
    isPending: true,
    createdBy: user,
  };
}

export interface TimebandNoteTableProps {
  user?: LoggedInUser | null;
  timer: ITimerPopulated;
}

export default function TimebandNoteTable(props: TimebandNoteTableProps) {
  const [pendingNote, setPendingNote] = useState<PendingTimerNote | null>(null);
  const [notes, setNotes] = useState<(PendingTimerNote | ITimerNotePopulated)[]>([...props.timer.notes]);

  useEffect(() => {
    setNotes([...props.timer.notes]);
  }, [props.timer.notes]);

  const onAddNoteClicked = useCallback(() => {
    setPendingNote(getDefaultPendingNote(props.timer));
  }, [props.timer]);

  const onSaveNoteClicked = useCallback(() => {
    if (pendingNote && pendingNote.note) {
      api
        .fetch(`/timers/${props.timer._id}/note`, {
          method: "POST",
          body: JSON.stringify({
            note: pendingNote.note,
          }),
        })
        .then(() => {
          setNotes([...notes, { ...pendingNote, isPending: false }]);
          setPendingNote(null);
        });
    }
  }, [notes, pendingNote, props.timer]);

  const onRemoveNoteClicked = useCallback(() => {
    setPendingNote(null);
  }, []);

  const onPendingNoteChanged = useCallback(
    (note: string) => {
      setPendingNote({ ...pendingNote, note });
    },
    [pendingNote],
  );

  const rows = useMemo(() => {
    const newNotes = [...notes];
    if (pendingNote) {
      newNotes.push(pendingNote);
    }
    return mapTimerNotesToTableRows(newNotes);
  }, [notes, pendingNote]);

  const COLUMN_DEFINITIONS: Record<string, ColumnDefinition<MappedNote>> = useMemo(() => {
    return {
      _id: {
        render: () => {
          return null;
        },
      },
      date: {
        render: ({ value }) => {
          return (
            <div>
              <label>{dayjs(value).format(DEFAULT_DATE_FORMAT)}</label>
            </div>
          );
        },
      },
      time: {
        render: ({ value }) => {
          return (
            <div>
              <label>{dayjs(value).format(DEFAULT_TIME_FORMAT)}</label>
            </div>
          );
        },
      },
      note: {
        render: ({ value, row }) => {
          if (row.isPending) {
            return (
              <input
                className="w-full rounded border border-neutral-500 px-2 py-1"
                type="text"
                value={value}
                onChange={(e) => onPendingNoteChanged(e.target.value)}
                disabled={!props.user}
              />
            );
          } else {
            return <label>{value}</label>;
          }
        },
      },
      user: {},
      isPending: {
        render: () => {
          return null;
        },
      },
    };
  }, [onPendingNoteChanged, props.user]);

  return (
    <div className="overflow-x-auto bg-white p-2 font-inter">
      <table className="w-full table-auto border-spacing-2 overflow-scroll text-left">
        <thead>
          <tr className="border-b-2 border-neutral-900 text-th">
            <th className="p-2">DATE</th>
            <th className="p-2">TIME</th>
            <th className="p-2">CASE NOTES</th>
            <th className="p-2">USER</th>
          </tr>
        </thead>
        <tbody className="text-sm">
          <tr className="h-2"></tr>
          {rows.map((row, idx) => (
            <tr className="border-b border-neutral-500" key={idx}>
              {Object.entries(row)
                .map(([key, value], idx2) => {
                  const renderVal = COLUMN_DEFINITIONS[key].render ? (
                    COLUMN_DEFINITIONS[key].render({ key, value, row, index: idx2 })
                  ) : (
                    <span>{value}</span>
                  );
                  if (renderVal) {
                    return (
                      <td className="p-2" key={idx2}>
                        {renderVal}
                      </td>
                    );
                  } else {
                    return null;
                  }
                })
                .filter((v) => v != null)}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-2">
        {props.user && pendingNote === null && <Button text="ADD NOTE" onClick={onAddNoteClicked} />}
        {props.user && pendingNote !== null && (
          <div>
            <Button className="mr-2 bg-success-300 hover:bg-success-500" text="SAVE NOTE" onClick={onSaveNoteClicked} />
            <Button className="bg-error-200 hover:bg-error-500" text="CANCEL" onClick={onRemoveNoteClicked} />
          </div>
        )}
      </div>
    </div>
  );
}
