import { ITimerPopulated } from "@dock-technologies/common";
import Button from "./Button";
import { LoggedInUser } from "../utilities/user";
import { useMemo } from "react";

export interface TimebandActionButtonsProps {
  timer: ITimerPopulated;
  user: LoggedInUser;
  onStopTimerClicked: () => void;
  onReactivateTimerClicked: () => void;
  onSendToAdministratorClicked: () => void;
  onFinalizeClicked: () => void;
}

export default function TimebandActionButtons({
  timer,
  user,
  onStopTimerClicked,
  onReactivateTimerClicked,
  onSendToAdministratorClicked,
  onFinalizeClicked,
}: TimebandActionButtonsProps) {
  const sendToAdminButton = useMemo(() => {
    return (
      <Button
        data-testid="send-to-administrator-button"
        className="bg-primary-500 text-white hover:bg-primary-700"
        text={timer.pendingFinalization ? "RESEND TO ADMINISTRATOR" : "SEND TO ADMINISTRATOR"}
        onClick={onSendToAdministratorClicked}
      />
    );
  }, [onSendToAdministratorClicked, timer]);

  const reactivateButton = useMemo(() => {
    return (
      <Button
        data-testid="reactivate-button"
        text="REACTIVATE"
        className="mr-2 bg-neutral-500 text-white hover:bg-neutral-900"
        onClick={onReactivateTimerClicked}
      />
    );
  }, [onReactivateTimerClicked]);

  const finalizeButton = useMemo(() => {
    return (
      <Button
        data-testid="finalize-button"
        className="bg-primary-500 text-white hover:bg-primary-700"
        text="FINALIZE"
        onClick={onFinalizeClicked}
      />
    );
  }, [onFinalizeClicked]);

  if (!user) {
    return <div></div>;
  }
  if (timer.active) {
    return (
      <Button
        data-testid="stop-button"
        text="STOP TIMER"
        className="bg-error-200 hover:bg-error-500"
        onClick={onStopTimerClicked}
      />
    );
  } else if (!timer.active) {
    if (timer.archived) {
      // No actions can happen to archived timer
      return <></>;
    } else {
      // Timer is not archived, rest of the button logic depends if user is super admin or admin or regular user
      if (user.isSuperAdmin || user.isAdmin) {
        // Super admins and admins are only users allowed to finalize (and reactivate)
        return (
          <div>
            {reactivateButton}
            {finalizeButton}
          </div>
        );
      } else {
        // User is a regular user, can only reactivate or send to administrator
        return (
          <div>
            {reactivateButton}
            {sendToAdminButton}
          </div>
        );
      }
    }
  }
}
