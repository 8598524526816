import { useContext, useEffect, useState } from "react";
import NavbarUser from "./NavbarUser";
import { AuthContext } from "../providers/AuthProvider";
import { getCurrentDateTimeString } from "../utilities/date";

export default function Navbar() {
  const { onLogout, user } = useContext(AuthContext);
  const loggedInUser = user.user;

  const [now, setNow] = useState(getCurrentDateTimeString());

  useEffect(() => {
    setInterval(() => setNow(getCurrentDateTimeString()), 1000);
  }, []);

  return (
    <div className="navbar flex flex-row bg-white px-2 py-2 sm:px-12">
      <div className="flex-1">
        <h2 className="text-xs text-primary-200 sm:text-nav">
          <div className="hidden sm:block">
            <span className="font-bold">IN</span>SIGHT <span className="font-bold">TIME</span>BAND
          </div>
          <div className="block sm:hidden">
            <div>
              <span className="font-bold">IN</span>SIGHT
            </div>
            <div>
              <span className="font-bold">TIME</span>BAND
            </div>
          </div>
        </h2>
      </div>
      <div className="flex-none">
        <div className="flex flex-col">
          <h4 className="text-right text-xs font-bold text-primary-900 sm:text-md">
            {loggedInUser && loggedInUser.orgName ? loggedInUser.orgName : "Unknown Organization"}
          </h4>
          <h4 className="text-right text-xs text-primary-900 sm:text-md">{now}</h4>
        </div>
      </div>
      <div className="ml-4 flex-none justify-end">
        {loggedInUser && <NavbarUser loggedInUser={loggedInUser} onLogout={onLogout} />}
      </div>
    </div>
  );
}
