import { dayjs } from "./date";
import { ITimepointPopulated, ITimerPopulated } from "./models";

export function getStoredTimesTotalRuntimeSeconds(
  storedTimes: ITimepointPopulated[],
  timer: ITimerPopulated,
) {
  if (!storedTimes || storedTimes.length === 0) {
    return 0;
  }
  const sortedTimes = sortStoredTimes(storedTimes);

  const first = sortedTimes.length < 2
    ? timer.startTime
    : sortedTimes[0].startTime;
  const last = timer.active
    ? new Date()
    : sortedTimes[sortedTimes.length - 1].startTime;

  return dayjs(last).diff(
    first,
    "seconds",
  );
}

export function getTimerTotalRuntimeSeconds(
  timer: ITimerPopulated,
) {
  return getStoredTimesTotalRuntimeSeconds(
    timer.storedTimes,
    timer,
  );
}

export function getStoredTimesRuntimeForBand(
  storedTimes: ITimepointPopulated[],
) {
  if (!storedTimes || storedTimes.length < 2) {
    return 0;
  }
  const sortedTimes = sortStoredTimes(storedTimes);

  const first = sortedTimes[0].startTime;
  const last = sortedTimes[sortedTimes.length - 1].startTime;

  return dayjs(last).diff(first, "seconds");
}

/**
 * Band is represented as 1/10 of a day
 */
export function getBand(runtimeSeconds: number) {
  const rawBand = runtimeSeconds / 60.0 / 60.0 / 24.0;
  const band = (Math.floor(rawBand * 10) / 10).toString();
  if (!band.includes(".")) {
    return `${band}.0`;
  } else {
    return band;
  }
}

export function sortStoredTimes(times: ITimepointPopulated[]) {
  return [...times].sort((a, b) =>
    dayjs(a.startTime) < dayjs(b.startTime) ? -1 : 1
  );
}
