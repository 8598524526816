export interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  text: string;
  className?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export default function Button(props: ButtonProps) {
  const className = props.className ?? "text-white bg-primary-500 hover:bg-primary-700";
  return (
    <button {...props} className={`${className} rounded px-4 py-2 text-xs sm:text-md`} onClick={props.onClick}>
      {props.text}
    </button>
  );
}
