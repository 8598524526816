import { RuntimeDisplay, dayjs } from "@dock-technologies/common";
import { useEffect, useMemo, useState } from "react";

export interface RuntimeTextProps {
  runtime: number;
  active?: boolean;
  display: RuntimeDisplay;
  showMinutesSeconds?: boolean;
  short?: boolean;
}

/**
 * @param runtimeSeconds The runtime value to format
 * @param display Controls the way the runtime string is formatted
 * @param short Whether to display the runtime string in short format
 */
export function formatRuntime(duration: plugin.Duration, display: RuntimeDisplay, short?: boolean) {
  switch (display) {
    case RuntimeDisplay.D: {
      // 	Runtime days should show the run time in tenths of days, rounding down EX: 1 hour = 0.0 days, 3 hours = 0.1 days
      const days = Math.floor((duration.asHours() / 24.0) * 10) / 10;

      return short ? (
        <>
          <span className="text-2xl font-bold sm:text-3xl">{days}</span> DY
        </>
      ) : (
        <>
          <span className="text-2xl font-bold sm:text-3xl">{days}</span> DAY{days !== 1 ? "S" : ""}
        </>
      );
    }
    case RuntimeDisplay.H: {
      const hours = Math.ceil(duration.asHours());
      return short ? (
        <>
          <span className="text-2xl font-bold sm:text-3xl">{hours}</span> HR
        </>
      ) : (
        <>
          <span className="text-2xl font-bold sm:text-3xl">{hours}</span> HOUR{hours !== 1 ? "S" : ""}
        </>
      );
    }
    case RuntimeDisplay.D_H: {
      const days = Math.floor(duration.asDays());
      const hours = Math.floor(duration.subtract(days, "days").asHours());
      return short ? (
        <>
          <span className="text-2xl font-bold sm:text-3xl">{days}</span> DY,{" "}
          <span className="text-2xl font-bold sm:text-3xl">{hours}</span> HR
        </>
      ) : (
        <>
          <span className="text-2xl font-bold sm:text-3xl">{days}</span> DAY{days !== 1 ? "S" : ""},{" "}
          <span className="text-2xl font-bold sm:text-3xl">{hours}</span> HOUR{hours !== 1 ? "S" : ""}
        </>
      );
    }
  }
}

let incrementSecondInterval: ReturnType<typeof setInterval> | null = null;

export default function RuntimeText(props: RuntimeTextProps) {
  const [runtime, setRuntime] = useState(props.runtime);
  useEffect(() => {
    setRuntime(props.runtime);
  }, [props.runtime]);
  useEffect(() => {
    if (incrementSecondInterval) {
      clearInterval(incrementSecondInterval);
      incrementSecondInterval = null;
    }
    if (props.active) {
      incrementSecondInterval = setInterval(() => {
        setRuntime(runtime + 1);
      }, 1000);
    }
  }, [props.active, runtime]);

  const duration = dayjs.duration({ seconds: runtime });
  const runtimeComponent = useMemo(() => formatRuntime(duration, props.display, props.short), [duration, props]);

  const days = Math.floor(duration.asDays());
  const hours = Math.floor(duration.subtract(days, "days").asHours());
  const minutes = Math.floor(duration.subtract(days, "days").subtract(hours, "hours").asMinutes());
  const seconds = Math.floor(
    duration.subtract(days, "days").subtract(hours, "hours").subtract(minutes, "minutes").asSeconds(),
  );

  const minutesSeconds = props.showMinutesSeconds ? (
    <label className="font-normal">
      ({minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")})
    </label>
  ) : (
    <></>
  );

  return (
    <label className="text-sm text-white sm:text-xl">
      {runtimeComponent}&nbsp;&nbsp;{minutesSeconds}
    </label>
  );
}
