import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from "./layouts/Layout";
import TimebandsActive from "./pages/TimebandsActive";
import TimebandView from "./pages/TimebandView";
import AuthProvider from "./providers/AuthProvider";
import Login from "./pages/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import AddTimer from "./pages/AddTimer";
import TimebandsHistory from "./pages/TimebandsHistory";
import Signup from "./pages/Signup";
import ResetPassword from "./pages/ResetPassword";
import LoggedOutLayout from "./layouts/LoggedOutLayout";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoggedOutLayout />}>
            <Route path={"/login"} element={<Login />} />
            <Route path={"/newuser/:orgId/:token"} element={<Signup />} />
            <Route path={"/auth/reset/:token"} element={<ResetPassword />} />
            <Route path="/" element={<Navigate to="/login" />} />
          </Route>
          <Route path="/" element={<Layout />}>
            <Route path={"/timers/add"} element={<AddTimer />} />
            <Route
              path={"/timebands/active"}
              element={
                <ProtectedRoute>
                  <TimebandsActive />
                </ProtectedRoute>
              }
            />
            <Route
              path={"/timebands/history"}
              element={
                <ProtectedRoute>
                  <TimebandsHistory />
                </ProtectedRoute>
              }
            />
            <Route path={"/timebands/view/:timerId"} element={<TimebandView />} />
            <Route path="/" element={<Navigate to="/timebands/active" />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
