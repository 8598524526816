import { Link, useLocation } from "react-router-dom";

function isActive(pathname: string, paths: string[]) {
  return paths.some((p) => pathname.startsWith(p));
}

export default function Sidebar() {
  const { pathname } = useLocation();

  return (
    <div className="bg-primary-900 sm:mt-[8rem]">
      <aside className="flex flex-row bg-neutral-500 text-sidebarMobile font-bold text-primary-900 sm:flex-col sm:text-lg">
        <section
          className={`px-4 py-2 sm:px-8 ${
            isActive(pathname, ["/timebands/active", "/timebands/view"]) ? "bg-white text-primary-200" : ""
          }`}
        >
          <Link to="/timebands/active">TIMEBANDS</Link>
        </section>
        <section
          className={`px-4 py-2 sm:px-8 ${
            isActive(pathname, ["/timebands/history"]) ? "bg-white text-primary-200" : ""
          }`}
        >
          <Link to="/timebands/history">CASE HISTORY</Link>
        </section>
        {/* {isDesktop() && (
          <section
            className={`px-4 py-2 sm:px-8 ${
              isActive(pathname, ["/timebands/analytics"]) ? "bg-white text-primary-200" : ""
            }`}
          >
            <Link to="/timebands/analytics">ANALYTICS</Link>
          </section>
        )} */}
      </aside>
    </div>
  );
}
