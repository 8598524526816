import { LocationType } from "@dock-technologies/common";

export function formatUserInitials(
  user: { firstName: string; lastName: string },
) {
  return user.firstName.substring(0, 1) + user.lastName.substring(0, 1);
}

export function formatTimerLocation(
  primary?: LocationType,
  secondary?: string,
) {
  if (primary) {
    const primaryString = primary === LocationType.ICU ? "ICU" : "SD";
    if (secondary) {
      return `${primaryString} ${secondary}`;
    } else {
      return primaryString;
    }
  } else {
    return "MISSING";
  }
}
