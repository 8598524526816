import {
  DeepPartial,
  DeviceType,
  DeviceTypeDirection,
  ICU_LOCATIONS,
  ITimerPopulated,
  LocationType,
  RuntimeDisplay,
  STEPDOWN_LOCATIONS,
  UpdateTimerRequestBody,
  getExpirationDays,
  getExpirationState,
  getDeviceDays,
  isValidMrn,
  isValidPartialMrn,
} from "@dock-technologies/common";
import { useCallback, useEffect, useMemo, useState } from "react";
import TimebandStepTable from "./TimebandStepTable";
import TimebandNoteTable from "./TimebandNoteTable";
import { EXPIRATION_STATE_BG_COLOR_MAP } from "../utilities/colors";
import RuntimeText from "./RuntimeText";
import { LoggedInUser } from "../utilities/user";
import TimerDetailFilters from "./TimerDetailFilters";

export interface TimerDetailProps {
  user?: LoggedInUser | null;
  timer: ITimerPopulated;
  runtimeDisplay: RuntimeDisplay;
  onTimerUpdated: (data: DeepPartial<UpdateTimerRequestBody>) => void | Promise<void>;
  debouncedOnTimerUpdated: (data: DeepPartial<UpdateTimerRequestBody>) => void | Promise<void>;
  onStepDeleted: (stepIndex: number) => void;
}

export default function TimerDetail({
  user,
  timer,
  onTimerUpdated,
  runtimeDisplay,
  debouncedOnTimerUpdated,
  onStepDeleted,
}: TimerDetailProps) {
  const [mrn, setMrn] = useState(timer.mrn ?? "");
  const [deviceType, setDeviceType] = useState<DeviceType | null>(timer.deviceType ?? null);
  const [deviceTypeDirection, setDeviceTypeDirection] = useState<DeviceTypeDirection | null>(
    timer.deviceTypeDirection ?? null,
  );
  const [location, setLocation] = useState<LocationType | null>(timer.locationPrimary ?? null);

  const initialSubLocations =
    timer.locationPrimary && timer.locationPrimary === LocationType.ICU
      ? ICU_LOCATIONS
      : timer.locationPrimary && timer.locationPrimary === LocationType.STEPDOWN
        ? STEPDOWN_LOCATIONS
        : [];
  const [subLocations, setSubLocations] = useState(initialSubLocations);
  const [subLocation, setSubLocation] = useState(timer.locationSecondary ?? "");

  useEffect(() => {
    setMrn(timer.mrn);
    setDeviceType(timer.deviceType);
    setDeviceTypeDirection(timer.deviceTypeDirection);
    setLocation(timer.locationPrimary);
    setSubLocation(timer.locationSecondary);
  }, [timer.deviceType, timer.deviceTypeDirection, timer.locationPrimary, timer.locationSecondary, timer.mrn]);

  const { expirationState } = useMemo(() => {
    return {
      expirationState: getExpirationState(timer.deviceType, timer.totalRuntime, timer.active, timer.archived),
    };
  }, [timer.active, timer.archived, timer.deviceType, timer.totalRuntime]);

  const onDeviceTypeChanged = useCallback(
    (options: DeviceType[]) => {
      const type = options[0];
      setDeviceType(type);
      setDeviceTypeDirection(null);
      onTimerUpdated({ deviceType: type, deviceTypeDirection: null });
    },
    [onTimerUpdated],
  );

  const onDeviceTypeDirectionChanged = useCallback(
    (options: DeviceTypeDirection[]) => {
      const type = options[0];
      setDeviceTypeDirection(type);
      onTimerUpdated({ deviceTypeDirection: type });
    },
    [onTimerUpdated],
  );

  const onMrnChanged = useCallback((mrnVal: string) => {
    if (isValidPartialMrn(mrnVal)) {
      setMrn(mrnVal);
    }
  }, []);

  const onSaveMrn = useCallback(() => {
    if (isValidMrn(mrn)) {
      onTimerUpdated({ mrn });
    }
  }, [mrn, onTimerUpdated]);

  const onLocationTypeChanged = useCallback(
    (options: LocationType[]) => {
      const type = options[0];
      setLocation(type);
      switch (type) {
        case LocationType.STEPDOWN: {
          setSubLocations(STEPDOWN_LOCATIONS);
          setSubLocation(null);
          onTimerUpdated({ locationPrimary: LocationType.STEPDOWN, locationSecondary: null });
          break;
        }
        case LocationType.ICU: {
          setSubLocations(ICU_LOCATIONS);
          setSubLocation(null);
          onTimerUpdated({ locationPrimary: LocationType.ICU, locationSecondary: null });
          break;
        }
      }
    },
    [onTimerUpdated],
  );

  const onSubLocationChanged = useCallback(
    (options: string[]) => {
      setSubLocation(options[0]);
      onTimerUpdated({ locationPrimary: location, locationSecondary: options[0] });
    },
    [location, onTimerUpdated],
  );

  const deviceDaysText = useMemo(() => {
    const actualDays = getDeviceDays(timer);
    const expirationDays = getExpirationDays(timer.deviceType);
    return `${actualDays} ACTUAL / ${expirationDays} EST. EXPIRATION`;
  }, [timer]);

  return (
    <div className={`${EXPIRATION_STATE_BG_COLOR_MAP[expirationState]} flex flex-col text-center`}>
      <label className="text-sm text-white">{timer.dockTimerID}</label>
      <RuntimeText runtime={timer.totalRuntime} display={runtimeDisplay} active={timer.active} showMinutesSeconds />
      <TimerDetailFilters
        user={user}
        archived={timer.archived}
        expirationState={expirationState}
        deviceType={deviceType}
        onDeviceTypeChanged={onDeviceTypeChanged}
        deviceTypeDirection={deviceTypeDirection}
        onDeviceTypeDirectionChanged={onDeviceTypeDirectionChanged}
        deviceDaysText={deviceDaysText}
        mrn={mrn}
        onMrnChanged={onMrnChanged}
        onSaveMrn={onSaveMrn}
        location={location}
        onLocationTypeChanged={onLocationTypeChanged}
        subLocations={subLocations}
        subLocation={subLocation}
        onSubLocationChanged={onSubLocationChanged}
      />
      <div className="flex-1 p-1 sm:p-4">
        <TimebandStepTable
          user={user}
          timer={timer}
          onStepDeleted={onStepDeleted}
          onTimerUpdated={onTimerUpdated}
          debouncedOnTimerUpdated={debouncedOnTimerUpdated}
        />
      </div>
      <div className="flex-1 p-4">
        <TimebandNoteTable user={user} timer={timer} />
      </div>
    </div>
  );
}
