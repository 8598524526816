import { RuntimeDisplay } from "@dock-technologies/common";

export interface RuntimeDisplayToggleProps {
  runtimeDisplay: RuntimeDisplay;
  onRuntimeDisplayChanged: (view: RuntimeDisplay) => void;
}

export default function RuntimeDisplayToggle(props: RuntimeDisplayToggleProps) {
  return (
    <div className="flex w-32 flex-row rounded-3xl bg-neutral-500 text-xs sm:text-md">
      <div
        className={`flex-1 cursor-pointer rounded-3xl pl-4 pt-2 ${
          props.runtimeDisplay === RuntimeDisplay.D ? "bg-white" : ""
        }`}
        onClick={() => props.onRuntimeDisplayChanged(RuntimeDisplay.D)}
      >
        {RuntimeDisplay.D}
      </div>
      <div
        className={`flex-1 cursor-pointer rounded-3xl p-2 ${
          props.runtimeDisplay === RuntimeDisplay.D_H ? "bg-white" : ""
        }`}
        onClick={() => props.onRuntimeDisplayChanged(RuntimeDisplay.D_H)}
      >
        {RuntimeDisplay.D_H}
      </div>
      <div
        className={`flex-1 cursor-pointer rounded-3xl pl-4 pt-2 ${
          props.runtimeDisplay === RuntimeDisplay.H ? "bg-white" : ""
        }`}
        onClick={() => props.onRuntimeDisplayChanged(RuntimeDisplay.H)}
      >
        {RuntimeDisplay.H}
      </div>
    </div>
  );
}
