import { ExpirationState } from "@dock-technologies/common";
import SummaryCard from "./SummaryCard";

export interface SummaryRowProps {
  timersSummary: Record<ExpirationState, number>;
}

export default function SummaryRow({ timersSummary }: SummaryRowProps) {
  return (
    <div className="flex flex-row bg-neutral-200 sm:px-12 px-1 py-2 sm:py-4">
      <div className="flex-1 sm:ml-20">
        <SummaryCard
          summaryNumber={timersSummary[ExpirationState.GREATER_THAN_1_DAY]}
          primaryText={timersSummary[ExpirationState.GREATER_THAN_1_DAY] !== 1 ? "TIMERS" : "TIMER"}
          secondaryText={ExpirationState.GREATER_THAN_1_DAY}
          state={ExpirationState.GREATER_THAN_1_DAY}
        />
      </div>
      <div className="flex-1 ml-2 mr-1 sm:ml-4 sm:mr-2">
        <SummaryCard
          summaryNumber={timersSummary[ExpirationState.LESS_THAN_1_DAY]}
          primaryText={timersSummary[ExpirationState.LESS_THAN_1_DAY] !== 1 ? "TIMERS" : "TIMER"}
          secondaryText={ExpirationState.LESS_THAN_1_DAY}
          state={ExpirationState.LESS_THAN_1_DAY}
        />
      </div>
      <div className="flex-1 ml-1 mr-2 sm:ml-2 sm:mr-4">
        <SummaryCard
          summaryNumber={timersSummary[ExpirationState.PAST_EXPIRATION]}
          primaryText={timersSummary[ExpirationState.PAST_EXPIRATION] !== 1 ? "TIMERS" : "TIMER"}
          secondaryText={ExpirationState.PAST_EXPIRATION}
          state={ExpirationState.PAST_EXPIRATION}
        />
      </div>
      <div className="flex-1 sm:mr-20">
        <SummaryCard
          summaryNumber={timersSummary[ExpirationState.NEED_FINALIZATION]}
          primaryText={timersSummary[ExpirationState.NEED_FINALIZATION] !== 1 ? "TIMERS" : "TIMER"}
          secondaryText={ExpirationState.NEED_FINALIZATION}
          state={ExpirationState.NEED_FINALIZATION}
        />
      </div>
    </div>
  );
}
