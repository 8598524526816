import { TimerStatistics } from "../utilities/timer";
import Button from "./Button";
import Label from "./Label";

export interface SummaryStatisticsProps extends TimerStatistics {
  onExportToCsvClicked: () => void;
}

export default function SummaryStatistics(props: SummaryStatisticsProps) {
  return (
    <div className="flex flex-col px-4 sm:flex-row">
      <div className="mt-4 w-64 flex-none text-center align-middle">
        <Label className="mr-2 text-primary-900">SUMMARY STATISTICS FOR SELECTED PERIOD</Label>
      </div>
      <div className="flex flex-1 flex-row border border-neutral-500 bg-neutral-200">
        <div className="flex-1 p-2">
          <div className="text-center text-lg font-bold text-primary-500 sm:text-3xl">{props.numDevices}</div>
          <div className="mt-2 text-center align-middle text-xs font-bold text-primary-900 sm:text-sm">
            # OF DEVICES USED
          </div>
        </div>
        <div className="flex-1 p-2">
          <div className="text-center text-lg font-bold text-primary-500 sm:text-3xl">{props.totalDeviceDays}</div>
          <div className="mt-2 text-center align-middle text-xs font-bold text-primary-900 sm:text-sm">
            TOTAL DEVICE DAYS
          </div>
        </div>
        <div className="flex-1 p-2">
          <div className="text-center text-lg font-bold text-primary-500 sm:text-3xl">
            {props.averageDaysPostExpiration}
          </div>
          <div className="mt-2 text-center align-middle text-xs font-bold text-primary-900 sm:text-sm">
            AVERAGE DAYS POST EXPIRATION
          </div>
        </div>
        <div className="flex-1 p-2">
          <div className="text-center text-lg font-bold text-primary-500 sm:text-3xl">
            {props.percentRemovedBeforeExpiration}
          </div>
          <div className="mt-2 text-center align-middle text-xs font-bold text-primary-900 sm:text-sm">
            REMOVED BEFORE EXPIRATION
          </div>
        </div>
      </div>
      <div className="ml-4 mt-4 flex-none text-center align-middle">
        <Button
          className="rounded-2xl border border-neutral-900 bg-neutral-200 text-primary-900 hover:bg-neutral-500"
          text="EXPORT TO CSV"
          onClick={props.onExportToCsvClicked}
        ></Button>
      </div>
    </div>
  );
}
