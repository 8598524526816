export interface UserIconProps {
  initials: string;
  className?: string;
}

export default function UserIcon(props: UserIconProps) {
  return (
    <div className={`bg-primary-200 rounded-full h-10 w-10 text-center pt-[.35rem] ${props.className}`}>
      <div className="text-xl align-middle">{props.initials}</div>
    </div>
  );
}
