import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

export const ONE_DAY_SECONDS = 24 * 60 * 60;

export const INPUT_DATE_FORMAT = "YYYY-MM-DD";
export const INPUT_TIME_FORMAT = "HH:mm";
export const INPUT_DATETIME_FORMAT = "YYYY-MM-DD HH:mm";

export const HEADER_DATE_FORMAT_TEMPLATE = "dddd, MMMM DD, YYYY - HH:mm:ss z";
export const HEADER_DATE_MOBILE_FORMAT_TEMPLATE =
  "ddd, MMM DD, YYYY - HH:mm:ss z";

export const DEFAULT_DATE_FORMAT = "MM/DD";
export const DEFAULT_TIME_FORMAT = "HH:mm";
export const DEFAULT_DATETIME_FORMAT = "MM/DD, HH:mm";

export const HISTORY_DATE_FORMAT = "MMM DD, YYYY";

export const DEVICE_DAYS_TIMEZONE = "America/New_York";

export function formatDefaultDateAndTime(date: Date) {
  const dateString = dayjs(date).format(DEFAULT_DATE_FORMAT);
  const timeString = dayjs(date).format(DEFAULT_TIME_FORMAT);
  return {
    dateString,
    timeString,
  };
}

export function secondsToDays(
  seconds: number,
  rounding: "up" | "down" | "round" = "round",
) {
  const days = seconds / 60.0 / 60.0 / 24.0;
  switch (rounding) {
    case "up": {
      return Math.ceil(days);
    }
    case "down": {
      return Math.floor(days);
    }
    case "round": {
      return Math.round(days);
    }
  }
}

export { dayjs };
