import { useCallback, useEffect, useMemo, useState } from "react";
import SelectListbox from "../components/SelectListbox";
import TimebandActiveTable from "../components/TimebandActiveTable";
import { SortOption, FilterOption, RuntimeDisplay, ITimerPopulated, ExpirationState } from "@dock-technologies/common";
import RuntimeDisplayToggle from "../components/RuntimeDisplayToggle";
import SummaryRow from "../components/SummaryRow";
import { fetchActiveTimers, fetchTimerSummary } from "../api";
import { SUMMARY_REFRESH_INTERVAL_MS, TIMEBAND_REFRESH_INTERVAL_MS } from "../utilities/refresh";
import { applyFiltersAndSort } from "../utilities/filter-sort";
import Button from "../components/Button";
import { isMobile } from "../utilities/mobile";
import Sidebar from "../components/Sidebar";

const SORT_OPTIONS = Object.values(SortOption);
const FILTER_OPTIONS = Object.values(FilterOption);

let fetchTimersInterval: ReturnType<typeof setInterval> | null = null;
let fetchSummaryInterval: ReturnType<typeof setInterval> | null = null;

export default function TimebandsActive() {
  // const [timebandView, setTimebandView] = useState(TimebandView.TABLE);
  const [runtimeDisplay, setRuntimeDisplay] = useState(RuntimeDisplay.D_H);
  const [activeTimers, setActiveTimers] = useState<ITimerPopulated[]>([]);
  const [sortOption, setSortOption] = useState(SortOption.NONE_SELECTED);
  const [filterOptions, setFilterOptions] = useState([FilterOption.ALL]);
  const [timersSummary, setTimersSummary] = useState<Record<ExpirationState, number> | null>(null);

  const onSortOptionChanged = useCallback((options: SortOption[]) => {
    const option = options[0];
    setSortOption(option);
  }, []);

  const onFilterOptionChanged = useCallback((options: FilterOption[]) => {
    // We want to toggle on/off the `All` filter on certain occasions
    // The most recent option selected is the last in `options` array
    const indexOfAll = options.findIndex((o) => o === FilterOption.ALL);
    if (indexOfAll === options.length - 1) {
      // Most recently selected `All`, so set to only `All`
      setFilterOptions([FilterOption.ALL]);
    } else if (indexOfAll !== -1) {
      // Most recent was not `All`, but filter contains `All`, so we need to remove `All`
      options.splice(indexOfAll, 1);
      setFilterOptions(options);
    } else {
      setFilterOptions(options);
    }
  }, []);

  // const onTimebandViewChanged = useCallback((view: TimebandView) => {
  //   setTimebandView(view);
  // }, []);

  const onRuntimeDisplayChanged = useCallback((runtime: RuntimeDisplay) => {
    setRuntimeDisplay(runtime);
  }, []);

  const removeFilters = useCallback(() => {
    setFilterOptions([FilterOption.ALL]);
  }, []);

  useEffect(() => {
    if (fetchTimersInterval) {
      clearInterval(fetchTimersInterval);
      fetchTimersInterval = null;
    }
    fetchTimersInterval = setInterval(() => {
      fetchActiveTimers().then((data) => setActiveTimers(data));
    }, TIMEBAND_REFRESH_INTERVAL_MS);
    fetchActiveTimers().then((data) => setActiveTimers(data));
    return () => {
      if (fetchTimersInterval) {
        clearInterval(fetchTimersInterval);
      }
    };
  }, []);

  useEffect(() => {
    if (fetchSummaryInterval) {
      clearInterval(fetchSummaryInterval);
      fetchSummaryInterval = null;
    }
    fetchSummaryInterval = setInterval(() => {
      fetchTimerSummary().then((data) => setTimersSummary(data));
    }, SUMMARY_REFRESH_INTERVAL_MS);
    fetchTimerSummary().then((data) => setTimersSummary(data));
    return () => {
      if (fetchSummaryInterval) {
        clearInterval(fetchSummaryInterval);
      }
    };
  }, []);

  const filteredSortedTimers = useMemo(() => {
    return applyFiltersAndSort(activeTimers, filterOptions, sortOption);
  }, [activeTimers, filterOptions, sortOption]);

  return (
    <div className="mt-5 flex flex-col">
      <div className="flex-1">
        <div className="flex flex-col sm:flex-row">
          {isMobile() && (
            <div className="mb-2 ml-2 mr-2 flex flex-none basis-6/12 flex-row">
              <Sidebar />
            </div>
          )}
          <div className="flex flex-1 flex-row sm:basis-4/12">
            <div className="ml-4 mr-2 mt-2 flex-none align-middle text-xs text-white sm:ml-0 sm:mt-3 sm:text-label">
              SORT
            </div>
            <div className="flex-1">
              <SelectListbox
                options={SORT_OPTIONS}
                selectedOptions={[sortOption]}
                onOptionChanged={onSortOptionChanged}
              />
            </div>
          </div>
          <div className="ml-4 flex flex-1 flex-row sm:ml-10 sm:basis-4/12">
            <div className="mr-2 mt-2 flex-none text-xs text-white sm:mt-3 sm:text-label">FILTER</div>
            <div className="max-w-96 flex-1">
              <SelectListbox
                options={FILTER_OPTIONS}
                selectedOptions={filterOptions}
                onOptionChanged={onFilterOptionChanged}
                multiple
              />
            </div>
          </div>
          <div className="ml-4 flex flex-none flex-row sm:ml-10 sm:basis-2/12">
            {/* <div className="mr-7 mt-3 flex-none text-xs text-white sm:mr-2 sm:text-label">VIEW</div>
            <div className="flex-1">
              <TimebandViewToggle view={timebandView} onTimebandViewChanged={onTimebandViewChanged} />
            </div> */}
          </div>
          <div className="ml-4 flex flex-none flex-row sm:ml-0 sm:basis-2/12">
            <div className="mr-2 mt-3 flex-none text-xs text-white sm:text-label">RUNTIME</div>
            <div className="flex-1">
              <RuntimeDisplayToggle runtimeDisplay={runtimeDisplay} onRuntimeDisplayChanged={onRuntimeDisplayChanged} />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 flex-1">{timersSummary && <SummaryRow timersSummary={timersSummary} />}</div>
      <div className="flex-1 flex-row bg-white py-3 text-center">
        <a
          className="mr-2 rounded bg-purple-500 px-4 py-2 text-xs text-white hover:bg-purple-700 sm:text-md"
          type="button"
          href="https://app.studyrandomizer.com/login"
          rel="nofollow"
          target="_blank"
        >
          GO TO RANDOMIZER
        </a>
        {filterOptions[0] !== FilterOption.ALL && <Button onClick={removeFilters} text="FILTERS ON - REMOVE FILTER" />}
      </div>

      <div className="flex-1 bg-white p-4">
        <TimebandActiveTable timers={filteredSortedTimers} runtimeDisplay={runtimeDisplay} />
      </div>
    </div>
  );
}
