import { ExpirationState } from "@dock-technologies/common";
import { EXPIRATION_STATE_BG_COLOR_MAP } from "../utilities/colors";

export interface SummaryCardProps {
  state: ExpirationState;
  summaryNumber: number;
  primaryText: string;
  secondaryText: string;
}

export default function SummaryCard(props: SummaryCardProps) {
  return (
    <div className={`flex flex-col p-1 sm:p-2 text-center text-white ${EXPIRATION_STATE_BG_COLOR_MAP[props.state]}`}>
      <span className="flex-1 font-bold">
        <span className="text-lg sm:text-2xl">{props.summaryNumber}</span>
        <span className="text-sm xm:text-lg ml-2">{props.primaryText}</span>
      </span>
      <span className="flex-1">
        <span className="text-[8px] sm:text-xs font-medium">{props.secondaryText}</span>
      </span>
    </div>
  );
}
