import { DeviceType } from "./enums";

export const STEPDOWN_LOCATIONS = [
  "312",
  "313",
  "314-1",
  "314-2",
  "315",
  "316",
  "317",
  "318",
  "319-1",
  "319-2",
  "320",
  "321-1",
  "321-2",
  "322-1",
  "322-2",
  "323",
  "324",
  "325",
];

export const ICU_LOCATIONS = [
  "260",
  "261",
  "263",
  "264",
  "265",
  "266",
  "267",
  "268",
  "269",
  "270",
  "271",
  "272",
  "273",
  "274",
  "275",
  "276",
  "277",
  "278",
  "279",
  "280",
  "281",
  "282",
  "283",
  "284",
  "285",
  "286",
  "287",
];

/**
 * Expiration time is in seconds
 */
export const DEVICE_TYPE_EXPIRATION_TIME_MAP: Record<DeviceType, number> = {
  [DeviceType.FEMORAL_CVC]: 2 * 24 * 60 * 60,
  [DeviceType.FEMORAL_QUINTON]: 7 * 24 * 60 * 60,
  [DeviceType.FEMORAL_ZOLL]: 4 * 24 * 60 * 60,

  [DeviceType.INTERNAL_JUGULAR_CVC]: 7 * 24 * 60 * 60,
  [DeviceType.INTERNAL_JUGULAR_QUINTON]: 14 * 24 * 60 * 60,
  [DeviceType.INTERNAL_JUGULAR_ZOLL]: 10 * 24 * 60 * 60,

  [DeviceType.UPPER_EXTREMITY_PICC]: 14 * 24 * 60 * 60,
};

export const INTERNAL_JUGULAR_DEVICE_TYPES = [
  DeviceType.INTERNAL_JUGULAR_CVC,
  DeviceType.INTERNAL_JUGULAR_ZOLL,
  DeviceType.INTERNAL_JUGULAR_QUINTON,
];

export const FEMORAL_DEVICE_TYPES = [
  DeviceType.FEMORAL_ZOLL,
  DeviceType.FEMORAL_QUINTON,
];

export const UPPER_EXTREMITY_DEVICE_TYPES = [
  DeviceType.UPPER_EXTREMITY_PICC,
];
