import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Fragment, useCallback } from "react";

export interface SelectListboxProps<T extends string> {
  options: T[];
  selectedOptions: T[];
  multiple?: boolean;
  disabled?: boolean;
  placeholder?: string;
  /** If `multiple` is not set, this will return an array with a single value at index 0 */
  onOptionChanged: (option: T[]) => void;
}

export default function SelectListbox<T extends string>(props: SelectListboxProps<T>) {
  const onOptionChanged = useCallback(
    (option: T[] | T) => {
      if (Array.isArray(option) && option.length === 0) {
        option = props.options[0];
      }
      const setOption = Array.isArray(option) ? option : [option];
      props.onOptionChanged(setOption);
    },
    [props],
  );

  return (
    <Listbox
      disabled={props.disabled}
      value={props.selectedOptions}
      onChange={onOptionChanged}
      multiple={props.multiple}
    >
      <div className="relative">
        <Listbox.Button className="relative h-8 w-full cursor-default rounded-lg border border-neutral-500 bg-white py-0 pl-1 pr-10 text-left text-sm shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:py-1 sm:pl-3 sm:text-sm">
          {props.selectedOptions.length > 0 ? (
            <span className="block truncate text-left">{props.selectedOptions.join(", ")}</span>
          ) : (
            <span className="block truncate text-left text-neutral-500">{props.placeholder}</span>
          )}
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </Listbox.Button>
        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Listbox.Options className="absolute z-50 w-full bg-white">
            {props.options.map((option, idx) => (
              <Listbox.Option
                key={option}
                value={option}
                className={({ active, selected }) =>
                  `relative cursor-default select-none border-x-2 border-b-2 border-neutral-200 py-2 pl-2 pr-4 text-left text-xs sm:text-sm ${
                    active || selected ? " bg-primary-900 font-bold text-white" : "text-primary-900"
                  } ${idx === 0 ? "border-t-2" : ""}`
                }
              >
                {option}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
