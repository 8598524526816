export default function LoggedOutFooter() {
  return (
    <footer className="container mx-auto mt-5 flex flex-col">
      <div className="icons mb-5 flex flex-1 flex-row items-center self-center">
        <div className="flex-none">
          <a href="http://twitter.com/docktech" rel="nofollow" target="_blank">
            <img src="social-twitter.png" width="30px" />
          </a>
        </div>
        <div className="ml-5 flex-none">
          <a href="http://www.linkedin.com/company/dock-technologies" rel="nofollow" target="_blank">
            <img src="social-lin.png" width="30px" />
          </a>
        </div>
      </div>

      <div className="flex-1 text-center text-md font-light text-primary-200">
        <a href="https://docktechnologies.com/team.html" rel="nofollow" target="_blank">
          <b>About us</b>
        </a>
        &nbsp; | &nbsp;
        <a href="https://angel.co/dock-technologies/jobs" rel="nofollow" target="_blank">
          <b>Careers</b>
        </a>
        &nbsp; | &nbsp;
        <a href="https://docktechnologies.com/privacypolicy.html" rel="nofollow" target="_blank">
          <b>Legal</b>
        </a>
        &nbsp; | &nbsp;
        <a href="mailto:hello@docktechnologies.com">
          <b>Contact us</b>
        </a>
      </div>

      <div className="flex-1">
        <div className="text-center font-light text-neutral-500">
          <p>
            <br />
            48 S Rio Grande Street, Salt Lake City, UT 84101
            <br />
            Copyright © Dock Technologies Inc. {new Date().getFullYear()}
          </p>
          <br />
        </div>
      </div>
    </footer>
  );
}
