import { Outlet } from "react-router-dom";
import LoggedOutNavbar from "../components/LoggedOutNavbar";
import LoggedOutFooter from "../components/LoggedOutFooter";

export default function LoggedOutLayout() {
  return (
    <div className="font-outfit">
      <div className="min-h-[100vh]">
        <LoggedOutNavbar />
        <div className="flex flex-row bg-primary-900">
          <div className="flex-1">
            <Outlet />
          </div>
        </div>
        <LoggedOutFooter />
      </div>
    </div>
  );
}
