import { QuickSelectFilter } from "@dock-technologies/common";

export interface QuickSelectToggleProps {
  quickSelectFilter: QuickSelectFilter;
  onQuickSelectFilterChanged: (filter: QuickSelectFilter) => void;
}

export default function QuickSelectToggle(props: QuickSelectToggleProps) {
  return (
    <div className="flex flex-row border-neutral-900 border bg-neutral-500 rounded-3xl text-xs sm:text-md">
      <div
        className={`rounded-3xl flex-1 cursor-pointer p-2 text-center ${
          props.quickSelectFilter === QuickSelectFilter.NONE ? "bg-white" : ""
        }`}
        onClick={() => props.onQuickSelectFilterChanged(QuickSelectFilter.NONE)}
      >
        {QuickSelectFilter.NONE}
      </div>
      <div
        className={`rounded-3xl flex-1 cursor-pointer p-2 text-center ${
          props.quickSelectFilter === QuickSelectFilter.LAST_30_DAYS ? "bg-white" : ""
        }`}
        onClick={() => props.onQuickSelectFilterChanged(QuickSelectFilter.LAST_30_DAYS)}
      >
        {QuickSelectFilter.LAST_30_DAYS}
      </div>
      <div
        className={`rounded-3xl flex-1 cursor-pointer p-2 text-center ${
          props.quickSelectFilter === QuickSelectFilter.LAST_60_DAYS ? "bg-white" : ""
        }`}
        onClick={() => props.onQuickSelectFilterChanged(QuickSelectFilter.LAST_60_DAYS)}
      >
        {QuickSelectFilter.LAST_60_DAYS}
      </div>
      <div
        className={`rounded-3xl flex-1 cursor-pointer p-2 text-center ${
          props.quickSelectFilter === QuickSelectFilter.LAST_90_DAYS ? "bg-white" : ""
        }`}
        onClick={() => props.onQuickSelectFilterChanged(QuickSelectFilter.LAST_90_DAYS)}
      >
        {QuickSelectFilter.LAST_90_DAYS}
      </div>
    </div>
  );
}
