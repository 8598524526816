/** This corresponds to the tailwind `sm` prefix/breakpoint */
const MOBILE_SCREEN_WIDTH_THRESHOLD = 640;

export function isMobile() {
  return window.screen.availWidth <= MOBILE_SCREEN_WIDTH_THRESHOLD;
}

export function isDesktop() {
  return !isMobile();
}
