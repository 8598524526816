import { DeviceType, DeviceTypeDirection, ExpirationState, LocationType } from "@dock-technologies/common";
import SelectListbox from "./SelectListbox";
import { LoggedInUser } from "../utilities/user";
import Label from "./Label";
import { isDesktop, isMobile } from "../utilities/mobile";

const DEVICE_TYPE_OPTIONS: DeviceType[] = Object.values(DeviceType);
const DEVICE_TYPE_DIRECTION_OPTIONS: DeviceTypeDirection[] = Object.values(DeviceTypeDirection);
const LOCATION_OPTIONS: LocationType[] = Object.values(LocationType);

function whiteIfFinalized(expirationState: ExpirationState) {
  return expirationState === ExpirationState.FINALIZED ? "text-white" : "";
}

export interface TimerDetailFiltersProps {
  user?: LoggedInUser | null;
  archived?: boolean;
  deviceType: DeviceType | null;
  expirationState: ExpirationState;
  onDeviceTypeChanged: (options: DeviceType[]) => void;
  deviceTypeDirection: DeviceTypeDirection | null;
  onDeviceTypeDirectionChanged: (options: DeviceTypeDirection[]) => void;
  deviceDaysText: string;
  mrn: string;
  onMrnChanged: (mrnVal: string) => void;
  onSaveMrn: () => void;
  location: LocationType | null;
  onLocationTypeChanged: (options: LocationType[]) => void;
  subLocations: string[];
  subLocation: string;
  onSubLocationChanged: (options: string[]) => void;
}

export default function TimerDetailFilters({
  user,
  archived,
  deviceType,
  expirationState,
  onDeviceTypeChanged,
  deviceTypeDirection,
  onDeviceTypeDirectionChanged,
  deviceDaysText,
  mrn,
  onMrnChanged,
  onSaveMrn,
  location,
  onLocationTypeChanged,
  subLocations,
  subLocation,
  onSubLocationChanged,
}: TimerDetailFiltersProps) {
  const disabled = !user || archived;

  return (
    <>
      <div className="flex w-full flex-col self-center sm:w-80 sm:flex-row">
        <div className="sm:basis-3/4">
          <div className="flex flex-row sm:block">
            <div className="mr-2 mt-1 basis-1/5 sm:mr-0 sm:mt-0">
              <Label className="sm:hidden">LINE TYPE</Label>
            </div>
            <div className="basis-4/5">
              <SelectListbox
                options={DEVICE_TYPE_OPTIONS}
                selectedOptions={deviceType ? [deviceType] : []}
                onOptionChanged={onDeviceTypeChanged}
                disabled={disabled}
                placeholder="(Select Line Type)"
              />
            </div>
          </div>
        </div>
        <div className="sm:basis-1/4">
          <div className="flex flex-row sm:block">
            <div className="mr-2 mt-1 basis-1/5 sm:mr-0 sm:mt-0">
              <Label className="sm:hidden">SIDE</Label>
            </div>
            <div className="basis-4/5">
              <SelectListbox
                options={DEVICE_TYPE_DIRECTION_OPTIONS}
                selectedOptions={deviceTypeDirection ? [deviceTypeDirection] : []}
                onOptionChanged={onDeviceTypeDirectionChanged}
                disabled={disabled}
                placeholder="(Select L or R)"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-1 flex flex-col sm:mt-2 sm:flex-row sm:px-4">
        {isDesktop() && (
          <div className="flex-1">
            <label className={`align-middle text-xs font-bold sm:text-md ${whiteIfFinalized(expirationState)}`}>
              DEVICE DAYS: <span className="text-sm">{deviceDaysText}</span>
            </label>
          </div>
        )}
        <div className="flex flex-1 flex-row">
          {user && !user.isSuperAdmin && (
            <>
              <div className="mt-1 basis-1/5">
                <Label className={whiteIfFinalized(expirationState)}>MRN</Label>
              </div>
              <div className="ml-2 basis-4/5 sm:ml-0">
                <input
                  className="w-full rounded px-2 py-1 sm:w-8/12"
                  type="text"
                  value={mrn}
                  onChange={(e) => onMrnChanged(e.target.value)}
                  onBlur={() => onSaveMrn()}
                  disabled={disabled}
                />
              </div>
            </>
          )}
        </div>
        <div className="flex flex-1 flex-row">
          <div className="mt-1 basis-1/5 sm:flex-1">
            <Label className={whiteIfFinalized(expirationState)}>LOCATION</Label>
          </div>
          <div className="ml-2 basis-2/5 sm:ml-0 sm:flex-1">
            <SelectListbox
              options={LOCATION_OPTIONS}
              selectedOptions={[location]}
              onOptionChanged={onLocationTypeChanged}
              disabled={disabled}
            />
          </div>
          <div className="ml-2 basis-2/5 sm:flex-1">
            <SelectListbox
              disabled={location == null || disabled}
              options={subLocations}
              selectedOptions={[subLocation]}
              onOptionChanged={onSubLocationChanged}
            />
          </div>
        </div>
        {isMobile() && (
          <div className="mt-1 flex-1">
            <label className="align-middle text-sm font-bold text-white">
              DEVICE DAYS: <span className="text-sm">{deviceDaysText}</span>
            </label>
          </div>
        )}
      </div>
    </>
  );
}
