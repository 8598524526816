import {
  dayjs,
  ExpirationState,
  FEMORAL_DEVICE_TYPES,
  FilterOption,
  getExpirationState,
  getSecondsUntilExpiration,
  INTERNAL_JUGULAR_DEVICE_TYPES,
  ITimerPopulated,
  LocationType,
  SortOption,
  UPPER_EXTREMITY_DEVICE_TYPES,
} from "@dock-technologies/common";

export function applyFiltersAndSort(
  timers: ITimerPopulated[],
  filters: FilterOption[],
  sort: SortOption,
) {
  let returnTimers: ITimerPopulated[] = [];
  if (filters.some((f) => f === FilterOption.ALL)) {
    returnTimers = [...timers];
  } else {
    for (const filter of filters) {
      switch (filter) {
        case FilterOption.ONLY_ACTIVE: {
          returnTimers = returnTimers.concat(timers.filter((t) => t.active));
          break;
        }
        case FilterOption.ONLY_INACTIVE: {
          returnTimers = returnTimers.concat(timers.filter((t) => !t.active));
          break;
        }
        case FilterOption.ALL_FEMORAL: {
          returnTimers = returnTimers.concat(
            timers.filter((t) =>
              t.deviceType && FEMORAL_DEVICE_TYPES.includes(t.deviceType)
            ),
          );
          break;
        }
        case FilterOption.ALL_INTERNAL_JUGULAR: {
          returnTimers = returnTimers.concat(
            timers.filter((t) =>
              t.deviceType &&
              INTERNAL_JUGULAR_DEVICE_TYPES.includes(t.deviceType)
            ),
          );
          break;
        }
        case FilterOption.ALL_UPPER_EXTREMITY: {
          returnTimers = returnTimers.concat(
            timers.filter((t) =>
              t.deviceType &&
              UPPER_EXTREMITY_DEVICE_TYPES.includes(t.deviceType)
            ),
          );
          break;
        }
        case FilterOption.GREATER_THAN_1_DAY: {
          returnTimers = returnTimers.concat(
            timers.filter(
              (t) =>
                getExpirationState(
                  t.deviceType,
                  t.totalRuntime,
                  t.active,
                  t.archived,
                ) ===
                  ExpirationState.GREATER_THAN_1_DAY,
            ),
          );
          break;
        }
        case FilterOption.LESS_THAN_1_DAY: {
          returnTimers = returnTimers.concat(
            timers.filter((t) =>
              getExpirationState(
                t.deviceType,
                t.totalRuntime,
                t.active,
                t.archived,
              ) ===
                ExpirationState.LESS_THAN_1_DAY
            ),
          );
          break;
        }
        case FilterOption.PAST_EXPIRATION: {
          returnTimers = returnTimers.concat(
            timers.filter((t) =>
              getExpirationState(
                t.deviceType,
                t.totalRuntime,
                t.active,
                t.archived,
              ) ===
                ExpirationState.PAST_EXPIRATION
            ),
          );
          break;
        }
        case FilterOption.ONLY_ICU: {
          returnTimers = returnTimers.concat(
            timers.filter((t) =>
              t.locationPrimary && t.locationPrimary === LocationType.ICU
            ),
          );
          break;
        }
        case FilterOption.ONLY_STEPDOWN: {
          returnTimers = returnTimers.concat(
            timers.filter((t) =>
              t.locationPrimary && t.locationPrimary === LocationType.STEPDOWN
            ),
          );
          break;
        }
        default:
          break;
      }
    }
  }

  // Gets unique timers
  returnTimers = Object.values(
    returnTimers.reduce<Record<string, ITimerPopulated>>((acc, curr) => {
      acc[curr._id] = curr;
      return acc;
    }, {}),
  );

  if (sort !== SortOption.NONE_SELECTED) {
    switch (sort) {
      case SortOption.EXPIRATION_CLOSEST: {
        returnTimers = returnTimers.sort((a, b) =>
          getSecondsUntilExpiration(a.totalRuntime, a.deviceType) >
              getSecondsUntilExpiration(b.totalRuntime, b.deviceType)
            ? 1
            : -1
        );
        break;
      }
      case SortOption.EXPIRATION_FARTHEST: {
        returnTimers = returnTimers.sort((a, b) =>
          getSecondsUntilExpiration(a.totalRuntime, a.deviceType) >
              getSecondsUntilExpiration(b.totalRuntime, b.deviceType)
            ? -1
            : 1
        );
        break;
      }
      case SortOption.ROOM_NUM_LARGEST: {
        returnTimers = returnTimers.sort((a, b) =>
          (a.locationSecondary ?? "0") > (b.locationSecondary ?? "0") ? -1 : 1
        );
        break;
      }
      case SortOption.ROOM_NUM_SMALLEST: {
        returnTimers = returnTimers.sort((a, b) =>
          (a.locationSecondary ?? "0") > (b.locationSecondary ?? "0") ? 1 : -1
        );
        break;
      }
      case SortOption.RUNTIME_LONGEST: {
        returnTimers = returnTimers.sort((
          a,
          b,
        ) => (a.totalRuntime > b.totalRuntime ? -1 : 1));
        break;
      }
      case SortOption.RUNTIME_SHORTEST: {
        returnTimers = returnTimers.sort((
          a,
          b,
        ) => (a.totalRuntime > b.totalRuntime ? 1 : -1));
        break;
      }
      case SortOption.START_TIME_OLDEST: {
        returnTimers = returnTimers.sort((
          a,
          b,
        ) => (a.startTime > b.startTime ? 1 : -1));
        break;
      }
      case SortOption.START_TIME_YOUNGEST: {
        returnTimers = returnTimers.sort((
          a,
          b,
        ) => (a.startTime > b.startTime ? -1 : 1));
        break;
      }
      case SortOption.STEPS_NUM_MOST: {
        returnTimers = returnTimers.sort((
          a,
          b,
        ) => (a.storedTimes.length > b.storedTimes.length ? -1 : 1));
        break;
      }
      case SortOption.STEPS_NUM_LEAST: {
        returnTimers = returnTimers.sort((
          a,
          b,
        ) => (a.storedTimes.length > b.storedTimes.length ? 1 : -1));
        break;
      }
      default: {
        break;
      }
    }
  }
  return returnTimers;
}

export function applyHistoryFiltersAndSort(
  timers: ITimerPopulated[],
  filters: FilterOption[],
  sort: SortOption,
  fromFilter: Date | null,
  toFilter: Date | null,
) {
  let filteredSorted = applyFiltersAndSort(timers, filters, sort);

  if (fromFilter != null) {
    const from = dayjs(fromFilter);
    filteredSorted = filteredSorted.filter((v) => dayjs(v.startTime) > from);
  }
  if (toFilter != null) {
    const to = dayjs(toFilter);
    filteredSorted = filteredSorted.filter((v) => dayjs(v.startTime) < to);
  }

  return filteredSorted;
}
