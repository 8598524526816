import { useCallback, useState } from "react";

export interface PasswordInputProps {
  password: string;
  onPasswordChanged: (newPassword: string) => void;
  label?: string;
}

export default function PasswordInput(props: PasswordInputProps) {
  const [passwordType, setPasswordType] = useState<"text" | "password">("password");
  const onShowHidePasswordClicked = useCallback(() => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  }, [passwordType]);
  return (
    <>
      <div className="flex-1">{props.label && <label className="font-bold text-primary-700">{props.label}</label>}</div>
      <div className="flex-1">
        <label className="relative block" htmlFor="passwordInput">
          <label
            onClick={onShowHidePasswordClicked}
            className="absolute right-2 mt-1 cursor-pointer font-bold text-primary-200"
          >
            {passwordType === "password" ? "SHOW" : "HIDE"}
          </label>
          <input
            id="passwordInput"
            className="w-full border border-primary-700 px-2 py-1"
            type={passwordType}
            value={props.password}
            onChange={(e) => props.onPasswordChanged(e.target.value)}
          />
        </label>
      </div>
    </>
  );
}
