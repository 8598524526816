export default function SignedOutHeader() {
  return (
    <div className="flex-row text-center">
      <h1 className="text-nav text-white">
        <div>
          <span className="font-bold">IN</span>SIGHT <span className="font-bold">TIME</span>BAND{" "}
          <span className="font-bold">DASHBOARD</span>
        </div>
      </h1>
    </div>
  );
}
