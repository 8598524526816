export type Mrn =
  `${number}${number}${number}${number} ${number}${number}${number}${number}`;

export const MRN_RE = /^[0-9A-Za-z]{4}[0-9A-Za-z]{4}$/;
export const PARTIAL_MRN_RE = /^[0-9A-Za-z]{0,4}([0-9A-Za-z]{0,4})?$/;

export function isValidMrn(mrnVal: string): mrnVal is Mrn {
  return mrnVal.length <= 8;
}

export function isValidPartialMrn(mrnVal: string) {
  return mrnVal.length <= 8;
}
