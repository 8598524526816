import { ExpirationState } from "@dock-technologies/common";

export const EXPIRATION_STATE_BG_COLOR_MAP: Record<ExpirationState, string> = {
  [ExpirationState.GREATER_THAN_1_DAY]: "bg-success-500",
  [ExpirationState.LESS_THAN_1_DAY]: "bg-warning-500",
  [ExpirationState.PAST_EXPIRATION]: "bg-error-500",
  [ExpirationState.NEED_FINALIZATION]: "bg-neutral-500",
  [ExpirationState.FINALIZED]: "bg-neutral-900",
};

export const EXPIRATION_STATE_BORDER_COLOR_MAP: Record<
  ExpirationState,
  string
> = {
  [ExpirationState.GREATER_THAN_1_DAY]: "border-success-500",
  [ExpirationState.LESS_THAN_1_DAY]: "border-warning-500",
  [ExpirationState.PAST_EXPIRATION]: "border-error-500",
  [ExpirationState.NEED_FINALIZATION]: "border-neutral-500",
  [ExpirationState.FINALIZED]: "border-neutral-900",
};
