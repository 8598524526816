import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useCallback, useState } from "react";
import Button from "./Button";

export interface ModalProps {
  title: string;
  message: string;
  confirmMessage: string;
  cancelMessage?: string;
  isOpen: boolean;
  swapColors?: boolean;
  children?: JSX.Element;
  onConfirmClicked: () => void | Promise<void>;
  onCancelClicked: () => void;
  onCloseClicked: () => void;
}

export default function Modal(props: ModalProps) {
  const [confirming, setConfirming] = useState(false);

  const handleConfirmClicked = useCallback(() => {
    setConfirming(true);
    const retVal = props.onConfirmClicked();
    if (retVal instanceof Promise) {
      retVal.then(() => setConfirming(false));
    } else {
      setConfirming(false);
    }
  }, [props]);

  return (
    <Transition appear show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10 font-inter" onClose={props.onCloseClicked}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-xl font-medium text-primary-900">
                  {props.title}
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{props.message}</p>
                </div>
                {props.children}
                <div className="mt-4">
                  <Button
                    className={
                      props.swapColors
                        ? "bg-error-200 hover:bg-error-500"
                        : "bg-primary-500 text-white hover:bg-primary-700"
                    }
                    onClick={handleConfirmClicked}
                    text={props.confirmMessage}
                    disabled={confirming}
                  />
                  {props.cancelMessage && (
                    <Button
                      className={`${
                        props.swapColors ? "bg-primary-500 hover:bg-primary-700" : "bg-error-200 hover:bg-error-500"
                      } ml-3`}
                      onClick={props.onCancelClicked}
                      text={props.cancelMessage}
                    />
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
