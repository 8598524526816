import { ExpirationState, ITimerPopulated } from "@dock-technologies/common";
import { LoggedInUser } from "./utilities/user";

export class ApiClient {
  #baseUrl: string;

  constructor(baseUrl?: string) {
    this.#baseUrl = baseUrl ?? import.meta.env.VITE_API_HOST;
  }

  async fetchUnauthed(pathname: `/${string}`, init?: RequestInit) {
    const url = this.#baseUrl + pathname;
    const headers = {
      ...(init?.headers ?? {}),
      ...this.getHeaders(),
    };

    return await fetch(url, {
      ...init,
      headers,
    });
  }

  async fetch(pathname: `/${string}`, init?: RequestInit) {
    const url = this.#baseUrl + pathname;
    const headers = {
      ...(init?.headers ?? {}),
      ...this.getAuthHeaders(),
    };

    return await fetch(url, {
      ...init,
      headers,
    });
  }

  getAuthHeaders() {
    // Server handles bad token?
    const token = localStorage.getItem("token") ?? "";

    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  }

  getHeaders() {
    return {
      "Content-Type": "application/json",
    };
  }
}

export const api = new ApiClient();

export async function fetchTimerSummary() {
  return await api
    .fetch("/timers/summary?moduleType=CVC")
    .then((res) => res.json())
    .then((data: { obj: Record<ExpirationState, number> }) => {
      return data.obj;
    });
}

export async function fetchActiveTimers() {
  return await api
    .fetch("/timers/active?moduleType=CVC")
    .then((res) => res.json())
    .then((data: { obj: ITimerPopulated[] }) => {
      return data.obj;
    });
}

export async function fetchHistoryTimers() {
  return await api
    .fetch("/timers/history?moduleType=CVC")
    .then((res) => res.json())
    .then((data: { obj: ITimerPopulated[] }) => {
      return data.obj;
    });
}

export async function fetchTimerDetail(
  timerId: string,
  user?: LoggedInUser | null,
) {
  if (user) {
    return await api
      .fetch(`/timers/detail/${timerId}`)
      .then((res) => res.json())
      .then((data: { obj: ITimerPopulated }) => data.obj);
  } else {
    return await api.fetchUnauthed(`/timers/detail/${timerId}`)
      .then((res) => res.json())
      .then((data: { obj: ITimerPopulated }) => data.obj);
  }
}
