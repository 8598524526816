import { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { api } from "../api";
import { AuthContext } from "../providers/AuthProvider";
import { ModuleType } from "@dock-technologies/common";
import { parseNewTimerQueryParams } from "../utilities/timer";

let fetchPromise: Promise<unknown> | null;

export default function AddTimer() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { token } = useContext(AuthContext);

  useEffect(() => {
    const parsedParams = parseNewTimerQueryParams(searchParams);

    const bodyJson = JSON.stringify({
      ...parsedParams,
      moduleType: ModuleType.CVC,
    });

    if (fetchPromise != null) {
      return;
    }

    if (token) {
      fetchPromise = api
        .fetch(`/timers/scanned-timer-token`, {
          method: "POST",
          body: bodyJson,
        })
        .then((res) => res.json())
        .then((data) => {
          if (data.obj && data.obj._id) {
            navigate(`/timebands/view/${data.obj._id}`);
          } else {
            navigate("/");
          }
        });
    } else {
      fetchPromise = api
        .fetchUnauthed(`/timers/scanned-timer`, {
          method: "POST",
          body: bodyJson,
        })
        .then((res) => res.json())
        .then((data) => {
          if (data.obj && data.obj._id) {
            navigate(`/timebands/view/${data.obj._id}`);
          } else {
            navigate("/");
          }
        });
    }
  }, [navigate, searchParams, token]);

  return <div></div>;
}
