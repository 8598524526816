import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { AuthContext } from "../providers/AuthProvider";
import { useContext } from "react";
import { isDesktop } from "../utilities/mobile";

export default function Layout() {
  const { token } = useContext(AuthContext);
  return (
    <div className="font-outfit">
      {token && <Navbar />}
      <div className="min-h-[100vh] bg-primary-900">
        <div className="container mx-auto flex flex-col sm:flex-row">
          <div className="flex-none">{token && isDesktop() && <Sidebar />}</div>
          <div className="flex-1">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
