import { createContext, useState } from "react";

import { api } from "../api";
import { LoggedInUser, decodeUserToken } from "../utilities/user";
import { ServiceError } from "@dock-technologies/common";

export interface LoginArgs {
  email: string;
  password: string;
  associateTimerId?: string;
}

export interface AuthContextInterface {
  user: LoggedInUser | null;
  token: string;
  onLogin: (args: LoginArgs) => Promise<{ token: string } | ServiceError>;
  onLogout: () => void;
}

export const AuthContext = createContext<AuthContextInterface>(null);

export default function AuthProvider({ children }: { children: React.ReactElement }) {
  const [token, setToken] = useState<string | null>(localStorage.getItem("token") ?? null);

  const onLogout = function () {
    localStorage.removeItem("token");
    setToken(null);
    window.location.href = "/login";
  };

  const onLogin = async function (args: LoginArgs) {
    const res: { token: string } = await api
      .fetchUnauthed("/user/api=signin", { method: "POST", body: JSON.stringify(args) })
      .then((r) => r.json());
    localStorage.setItem("token", res.token);
    setToken(res.token);
    return res;
  };

  let user: LoggedInUser;
  try {
    user = token ? decodeUserToken(token) : null;
  } catch {
    onLogout();
    return null;
  }

  const value = {
    user,
    token,
    onLogin,
    onLogout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
