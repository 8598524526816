import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../api";
import SignedOutHeader from "../components/SignedOutHeader";
import Button from "../components/Button";
import PasswordInput from "../components/PasswordInput";

export default function ResetPassword() {
  const navigate = useNavigate();
  const { token } = useParams();

  const [error, setError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const onResetPasswordClicked = useCallback(() => {
    if (!newPassword || !confirmNewPassword) {
      setError("Both fields are required");
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setError("Passwords must match");
      return;
    }

    api
      .fetch("/user/api=resetPassword", { method: "POST", body: JSON.stringify({ password: newPassword, token }) })
      .then(() => {
        navigate("/login");
      });
  }, [confirmNewPassword, navigate, newPassword, token]);

  return (
    <div className="mb-32 mt-16 flex flex-col">
      <SignedOutHeader />
      <div className="mt-5 flex flex-col rounded-xl bg-white p-8 shadow-md sm:mx-[33%]">
        <div className="flex-1 text-center">
          <h2 className="text-wb text-primary-900">RESET PASSWORD</h2>
        </div>
        {error && (
          <div className="flex-1 text-center">
            <h2 className="mt-2 text-lg text-error-500">{error}</h2>
          </div>
        )}
        <div className="mt-5 flex-1">
          <div className="flex flex-col">
            <PasswordInput
              label="NEW PASSWORD"
              password={newPassword}
              onPasswordChanged={(newP) => {
                setError("");
                setNewPassword(newP);
              }}
            />
          </div>
        </div>
        <div className="mt-5 flex-1">
          <div className="flex flex-col">
            <PasswordInput
              label="REENTER PASSWORD"
              password={confirmNewPassword}
              onPasswordChanged={(newP) => {
                setError("");
                setConfirmNewPassword(newP);
              }}
            />
          </div>
        </div>
        <div className="mt-5 flex-1">
          <Button
            className="text-bold w-full bg-primary-500 text-white hover:bg-primary-700"
            text="RESET PASSWORD"
            onClick={onResetPasswordClicked}
          />
        </div>
      </div>
    </div>
  );
}
