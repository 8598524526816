import {
  DEFAULT_DATETIME_FORMAT,
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
  DeepPartial,
  INPUT_DATETIME_FORMAT,
  INPUT_DATE_FORMAT,
  INPUT_TIME_FORMAT,
  ITimepointPopulated,
  ITimerPopulated,
  StoredTimeStep,
  UpdateTimerRequestBody,
  dayjs,
  getBand,
  getStoredTimesRuntimeForBand,
  sortStoredTimes,
} from "@dock-technologies/common";
import { ColumnDefinition } from "../types";
import { useCallback, useEffect, useMemo, useState } from "react";
import Button from "./Button";
import SelectListbox from "./SelectListbox";
import { LoggedInUser, getLoggedInUser, getUniqueUsers } from "../utilities/user";
import { formatUserInitials } from "../utilities/format";
import Input from "./Input";
import Modal from "./Modal";
import { api } from "../api";
import { isDesktop, isMobile } from "../utilities/mobile";

const MANUAL_SOURCE = "Manual";

interface PendingStoredTime extends ITimepointPopulated {
  isPending?: boolean;
}

type MappedStoredTime = ReturnType<typeof mapStoredTimesToTableRows>[0];

export function mapStoredTimesToTableRows(timer: ITimerPopulated) {
  return timer.storedTimes.map((time, idx, allStoredTimes) => {
    const editUsers = (time.edits ?? []).map((e) => e.user);
    const timerUsers = getUniqueUsers([...editUsers, time.createdBy, time.modifiedBy]);

    let band: string | null = null;
    if (time.step !== StoredTimeStep.PLACEMENT) {
      const storedTimesUpToCurrentTime = sortStoredTimes(allStoredTimes.slice(0, idx + 1));
      const runtimeUpToCurrentTime = getStoredTimesRuntimeForBand(storedTimesUpToCurrentTime);
      band = getBand(runtimeUpToCurrentTime);
    }

    let source: string;
    if (time.manuallyAdded) {
      source = MANUAL_SOURCE;
    } else {
      if (isMobile()) {
        source = "TB";
      } else {
        source = "Timeband";
      }
    }

    return {
      _id: time._id,
      date: time.startTime,
      time: time.startTime,
      band,
      step: time.step,
      notes: time.note,
      user: timerUsers.length > 0 ? timerUsers.map((u) => formatUserInitials(u)).join(",") : "N/A",
      source,
      isPending: "isPending" in time && time.isPending,
      action: "DELETE",
    };
  });
}

export function getDefaultPendingStoredTime(): PendingStoredTime {
  const createdAt = new Date();
  const startTime = new Date();

  const { user } = getLoggedInUser();

  return {
    _id: "",
    duration: 0,
    createdAt,
    startTime,
    modifiedAt: createdAt,
    note: "",
    manuallyAdded: true,
    step: undefined,
    isPending: true,
    createdBy: user,
    modifiedBy: user,
  };
}

const STORED_TIME_STEP_OPTIONS: StoredTimeStep[] = Object.values(StoredTimeStep);
const BAND_0_STEP_OPTIONS: StoredTimeStep[] = [StoredTimeStep.PLACEMENT, StoredTimeStep.TIMEBAND_STARTED_NOT_PLACEMENT];

function isSingletonStep(step: StoredTimeStep | string) {
  return (
    step === StoredTimeStep.PLACEMENT ||
    step === StoredTimeStep.DISCONTINUED ||
    step === StoredTimeStep.TIMEBAND_STARTED_NOT_PLACEMENT
  );
}

const ONLY_FIRST_STEP = [StoredTimeStep.PLACEMENT, StoredTimeStep.TIMEBAND_STARTED_NOT_PLACEMENT];

export interface TimebandStepTableProps {
  user?: LoggedInUser | null;
  timer: ITimerPopulated;
  onTimerUpdated: (data: DeepPartial<UpdateTimerRequestBody>) => void | Promise<void>;
  debouncedOnTimerUpdated: (data: DeepPartial<UpdateTimerRequestBody>) => void | Promise<void>;
  onStepDeleted: (stepIndex: number) => void;
}

export default function TimebandStepTable(props: TimebandStepTableProps) {
  const [stepToDelete, setStepToDelete] = useState<string | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [pendingStoredTime, _setPendingStoredTime] = useState<PendingStoredTime | null>(null);
  const [storedTimes, setStoredTimes] = useState([...props.timer.storedTimes]);

  const disabled = !props.user || props.timer.archived;

  useEffect(() => {
    setStoredTimes([...props.timer.storedTimes]);
  }, [props.timer]);

  const setPendingStoredTime = useCallback(
    (step: Partial<PendingStoredTime>) => {
      const previousTime = pendingStoredTime ?? getDefaultPendingStoredTime();
      _setPendingStoredTime({ ...previousTime, ...step });
    },
    [pendingStoredTime],
  );

  const setPendingStoredTimeDate = useCallback(
    (date: string) => {
      if (isMobile()) {
        const dateObj = dayjs(date, INPUT_DATETIME_FORMAT);
        setPendingStoredTime({ startTime: dateObj.toDate() });
      } else {
        const dateObj = dayjs(date, INPUT_DATE_FORMAT);

        const newStartTime = dayjs(pendingStoredTime.startTime)
          .year(dateObj.year())
          .month(dateObj.month())
          .date(dateObj.date());

        setPendingStoredTime({ startTime: newStartTime.toDate() });
      }
    },
    [pendingStoredTime, setPendingStoredTime],
  );

  const setPendingStoredTimeTime = useCallback(
    (time: string) => {
      const incomingTime = dayjs(time, INPUT_TIME_FORMAT);
      const now = dayjs();
      // Prevents the user from selecting a time in the future
      const timeObj = incomingTime > now ? now : incomingTime;

      const newStartTime = dayjs(pendingStoredTime.startTime)
        .set("hour", timeObj.hour())
        .set("minute", timeObj.minute())
        .set("second", timeObj.second());

      setPendingStoredTime({ startTime: newStartTime.toDate() });
    },
    [pendingStoredTime, setPendingStoredTime],
  );

  const onStoredTimeStepChanged = useCallback(
    (step: StoredTimeStep, row: MappedStoredTime) => {
      // Only allow one placement, discontinued, timeband started step
      if (isSingletonStep(step) && storedTimes.some((t) => t.step === step)) {
        return;
      }

      const stepIndex = storedTimes.findIndex((s) => s._id === row._id);
      // First step must be either placement or discontinued
      if (stepIndex === 0 && !ONLY_FIRST_STEP.includes(step)) {
        return;
      }

      if (row.isPending) {
        if (step === StoredTimeStep.TIMEBAND_STARTED_NOT_PLACEMENT) {
          // Just replace with Placement to override what we are currently doing
          setPendingStoredTime({ ...getDefaultPendingStoredTime(), step: StoredTimeStep.PLACEMENT });
        } else {
          setPendingStoredTime({ step });
        }
      } else {
        const updateStoredTime = { id: row._id, step };
        const foundIndex = storedTimes.findIndex((t) => t._id === row._id);
        storedTimes[foundIndex].step = step;
        setStoredTimes([...storedTimes]);

        if (step === StoredTimeStep.TIMEBAND_STARTED_NOT_PLACEMENT) {
          setPendingStoredTime({
            ...getDefaultPendingStoredTime(),
            startTime: row.date,
            step: StoredTimeStep.PLACEMENT,
          });
        }

        if (step === StoredTimeStep.OTHER_SEE_NOTE && !row.notes) {
          /**
           * TODO(@curtislarson): Add some sort of error notification here that we
           * cannot save unless they enter something in the note column
           */
        } else {
          props.onTimerUpdated({ storedTimes: [updateStoredTime] });
        }
      }
    },
    [props, setPendingStoredTime, storedTimes],
  );

  const onStoredTimeNoteChanged = useCallback(
    (note: string, row: MappedStoredTime) => {
      if (row.isPending) {
        setPendingStoredTime({ note });
      } else {
        const updateStoredTime = { id: row._id, note };
        const foundIndex = storedTimes.findIndex((t) => t._id === row._id);
        storedTimes[foundIndex].note = note;
        setStoredTimes([...storedTimes]);

        props.onTimerUpdated({ storedTimes: [updateStoredTime] });
      }
    },
    [props, setPendingStoredTime, storedTimes],
  );

  const onAddStepClicked = useCallback(() => {
    if (pendingStoredTime === null) {
      _setPendingStoredTime(getDefaultPendingStoredTime());
    }
  }, [pendingStoredTime]);

  const onSaveStepClicked = useCallback(() => {
    if (pendingStoredTime.step === StoredTimeStep.OTHER_SEE_NOTE && !pendingStoredTime.note) {
      /**
       * TODO(@curtislarson): Add some sort of error notification here that we
       * cannot save unless they enter something in the note column
       */
    } else {
      const updateTimer = { storedTimes: [pendingStoredTime] };
      setStoredTimes([...storedTimes, pendingStoredTime]);
      props.onTimerUpdated(updateTimer);
      _setPendingStoredTime(null);
    }
  }, [pendingStoredTime, props, storedTimes]);

  const onRemoveStepClicked = useCallback(() => {
    _setPendingStoredTime(null);
  }, [_setPendingStoredTime]);

  const onDeleteStepClicked = useCallback(
    (stepId: string) => {
      setStepToDelete(stepId);
      setShowDeleteModal(true);
    },
    [setStepToDelete, setShowDeleteModal],
  );
  const cancelDeleteStep = useCallback(() => {
    setStepToDelete(null);
    setShowDeleteModal(false);
  }, []);
  const performDeleteStep = useCallback(() => {
    if (stepToDelete) {
      api
        .fetch(`/timers/${props.timer._id}/timepoint`, {
          method: "DELETE",
          body: JSON.stringify({ id: stepToDelete }),
        })
        .then(() => {
          const indexOfStoredTime = storedTimes.findIndex((s) => s._id === stepToDelete);
          if (indexOfStoredTime !== -1) {
            storedTimes.splice(indexOfStoredTime, 1);
            setStoredTimes([...storedTimes]);
            props.onStepDeleted(indexOfStoredTime);
          }
          setStepToDelete(null);
          setShowDeleteModal(false);
        });
    }
  }, [props, stepToDelete, storedTimes]);

  const rows = useMemo(() => {
    const newStoredTimes: (ITimepointPopulated | PendingStoredTime)[] = [...storedTimes];
    if (pendingStoredTime) {
      if (pendingStoredTime.step === StoredTimeStep.PLACEMENT) {
        const indexOfTimebandStarted = newStoredTimes.findIndex(
          (t) => t.step === StoredTimeStep.TIMEBAND_STARTED_NOT_PLACEMENT,
        );
        if (indexOfTimebandStarted !== -1) {
          newStoredTimes.splice(indexOfTimebandStarted, 0, pendingStoredTime);
        } else {
          newStoredTimes.push(pendingStoredTime);
        }
      } else {
        newStoredTimes.push(pendingStoredTime);
      }
    }

    return mapStoredTimesToTableRows({ ...props.timer, storedTimes: sortStoredTimes(newStoredTimes) });
  }, [pendingStoredTime, props.timer, storedTimes]);

  const COLUMN_DEFINITIONS: Record<string, ColumnDefinition<MappedStoredTime>> = useMemo(() => {
    const now = dayjs();
    return {
      _id: {
        render: () => {
          return null;
        },
      },
      date: {
        render: ({ row, value }) => {
          if (isMobile()) {
            if (row.isPending) {
              return (
                <input
                  className="w-32 rounded border border-neutral-500 px-2 py-1"
                  type="datetime-local"
                  value={dayjs(value).format(INPUT_DATETIME_FORMAT)}
                  onChange={(e) => setPendingStoredTimeDate(e.target.value)}
                  disabled={disabled}
                  max={now.format(INPUT_DATETIME_FORMAT)}
                />
              );
            } else {
              return <label>{dayjs(value).format(DEFAULT_DATETIME_FORMAT)}</label>;
            }
          } else {
            if (row.isPending) {
              return (
                <input
                  className="w-32 rounded border border-neutral-500 px-2 py-1"
                  type="date"
                  value={dayjs(value).format(INPUT_DATE_FORMAT)}
                  onChange={(e) => setPendingStoredTimeDate(e.target.value)}
                  disabled={disabled}
                  max={now.format(INPUT_DATE_FORMAT)}
                />
              );
            } else {
              return <label>{dayjs(value).format(DEFAULT_DATE_FORMAT)}</label>;
            }
          }
        },
      },
      time: {
        render: ({ row, value }) => {
          if (isMobile()) {
            return null;
          }
          if (row.isPending) {
            return (
              <input
                className="w-24 rounded border border-neutral-500 px-2 py-1"
                type="time"
                value={dayjs(value).format(INPUT_TIME_FORMAT)}
                onChange={(e) => setPendingStoredTimeTime(e.target.value)}
                disabled={disabled}
                max={now.format(INPUT_TIME_FORMAT)}
              />
            );
          } else {
            return <label>{dayjs(value).format(DEFAULT_TIME_FORMAT)}</label>;
          }
        },
      },
      band: {
        render: ({ value }) => {
          if (isMobile()) {
            return null;
          }
          if (value != null) {
            return (
              <label className="font-bold">
                {value} <span className="font-normal">D</span>
              </label>
            );
          } else {
            return <label></label>;
          }
        },
      },
      step: {
        render: ({ value, row, index }) => {
          const stepVal = value as StoredTimeStep;
          if (!disabled) {
            return (
              <div>
                <SelectListbox
                  options={index === 0 ? BAND_0_STEP_OPTIONS : STORED_TIME_STEP_OPTIONS}
                  selectedOptions={stepVal ? [stepVal] : []}
                  onOptionChanged={(options: StoredTimeStep[]) => onStoredTimeStepChanged(options[0], row)}
                  disabled={disabled}
                />
              </div>
            );
          } else {
            return <label>{stepVal}</label>;
          }
        },
      },
      notes: {
        render: ({ value, row }) => {
          if (isMobile()) {
            return null;
          }
          if (!disabled) {
            return (
              <Input
                id={`note-${row._id}`}
                className="w-24 rounded border border-neutral-500 px-2 py-1 sm:w-52"
                type="text"
                value={value}
                onChange={(e) => onStoredTimeNoteChanged(e.target.value, row)}
                disabled={disabled}
              />
            );
          } else {
            return <label>{value}</label>;
          }
        },
      },
      user: {
        render: ({ value }) => {
          if (isMobile()) {
            return null;
          } else {
            return <span>{value}</span>;
          }
        },
      },
      source: {},
      isPending: {
        render: () => {
          return null;
        },
      },
      action: {
        render: ({ row }) => {
          if (isMobile()) {
            return null;
          }
          if (!props.user || row.source !== MANUAL_SOURCE) {
            return <div></div>;
          } else {
            return (
              <label className="cursor-pointer" onClick={() => onDeleteStepClicked(row._id)}>
                ❌
              </label>
            );
          }
        },
      },
    };
  }, [
    disabled,
    onDeleteStepClicked,
    onStoredTimeNoteChanged,
    onStoredTimeStepChanged,
    props.user,
    setPendingStoredTimeDate,
    setPendingStoredTimeTime,
  ]);

  return (
    <div className="bg-white p-2 font-inter">
      <table className="w-full table-auto border-spacing-2 text-left">
        <thead>
          <tr className="border-b-2 border-neutral-900 text-ths sm:text-th">
            {isDesktop() && (
              <>
                <th className="p-1 sm:p-2">DATE</th>
                <th className="p-1 sm:p-2">TIME</th>
              </>
            )}
            {isMobile() && <th className="p-1 sm:p-2">DATE,TIME</th>}
            {isDesktop() && <th className="p-1 sm:p-2">BAND</th>}
            <th className="p-1 sm:p-2">STEP</th>
            {isDesktop() && <th className="p-1 sm:p-2">STEP NOTES</th>}
            {isDesktop() && <th className="p-1 sm:p-2">USER</th>}
            {isDesktop() && <th className="p-1 sm:p-2">SOURCE</th>}
            {isMobile() && <th className="p-1 sm:p-2">SRC</th>}
            {isDesktop() && <th className="p-1 sm:p-2"></th>}
          </tr>
        </thead>
        <tbody className="text-sm">
          <tr className="h-2"></tr>
          {rows.map((row, idx) => (
            <tr className="border-b border-neutral-500" key={idx}>
              {Object.entries(row)
                .map(([key, value], idx2) => {
                  const renderVal =
                    COLUMN_DEFINITIONS[key] && COLUMN_DEFINITIONS[key].render ? (
                      COLUMN_DEFINITIONS[key].render({ key, value, row, index: idx })
                    ) : (
                      <span>{value}</span>
                    );
                  // A null `renderVal` means we dont want to render the value for that column
                  if (renderVal) {
                    return (
                      <td className="w-max p-2" key={idx2}>
                        {renderVal}
                      </td>
                    );
                  } else {
                    return null;
                  }
                })
                .filter((v) => v != null)}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-4">
        {!disabled && pendingStoredTime === null && <Button text="ADD STEP" onClick={onAddStepClicked} />}
        {!disabled && pendingStoredTime !== null && (
          <div>
            <Button className="mr-2 bg-success-300 hover:bg-success-500" text="SAVE STEP" onClick={onSaveStepClicked} />
            <Button className="bg-error-200 hover:bg-error-500" text="CANCEL" onClick={onRemoveStepClicked} />
          </div>
        )}
      </div>
      <Modal
        swapColors
        isOpen={showDeleteModal}
        onCloseClicked={cancelDeleteStep}
        onCancelClicked={cancelDeleteStep}
        onConfirmClicked={performDeleteStep}
        confirmMessage="Delete"
        cancelMessage="Cancel"
        title="Confirm Delete"
        message="Are you sure you want to delete this step?"
      />
    </div>
  );
}
