import {
  dayjs,
  getExpirationDays,
  getPostExpiration,
  ITimerPopulated,
  secondsToDays,
} from "@dock-technologies/common";

export function parseNewTimerQueryParams(searchParams: URLSearchParams) {
  const uid = searchParams.get("UID");
  const runtime = searchParams.get("runtime");
  const seconds = searchParams.get("seconds");
  const stillRunning = searchParams.get("stillrunning");
  const numStoredTimes = searchParams.get("numstoredtimes");

  const runtimeSeconds = (parseInt(runtime) * 60) + parseInt(seconds);
  const active = parseInt(stillRunning) == 1;
  const timerStartTime = active
    ? new Date(Date.now() - (runtimeSeconds * 1000))
    : null;

  let storedTimes: {
    duration: number;
    manuallyAdded: boolean;
    startTime: Date | null;
  }[] = [];

  if (numStoredTimes && parseInt(numStoredTimes) > 0) {
    const storedtimes = searchParams.get("storedtimes");
    const checksum = searchParams.get("checksum");

    if (checksum == null) {
      throw new Error("Checksum not supplied, cannot verify timer!");
    }

    const storedTimesStrings = storedtimes.split("-");

    // let indexMultiplier = 1;
    // let calculatedChecksum = 0;
    storedTimes = storedTimesStrings.map((storedTimeString) => {
      const minSec = storedTimeString.split(":");
      // take minute value for calculating checksum
      // calculatedChecksum += parseInt(minSec[0]) * indexMultiplier;
      // increment multiplier
      // indexMultiplier++;
      const min = parseInt(minSec[0]);
      let sec = parseInt(minSec[1]);
      if (sec < 0 || sec > 59) {
        sec = 0;
      }
      const storedTimeDurationSeconds = min * 60 + sec;
      if (storedTimeDurationSeconds <= runtimeSeconds) {
        return {
          duration: storedTimeDurationSeconds,
          manuallyAdded: false,
          startTime: timerStartTime
            ? dayjs(timerStartTime).add(storedTimeDurationSeconds, "seconds")
              .toDate()
            : null,
        };
      } else {
        console.log(
          "Timepoint not stored because it was longer duration than the timer itself",
        );
        return null;
      }
    }).filter((v): v is NonNullable<typeof v> => v != null);

    // TODO(@curtislarson): This is disabled for cvc module until checksum can be fixed in timer
    // calculatedChecksum = (98 - ((calculatedChecksum * 100) % 97)) % 97;
    // if (parseInt(checksum) != calculatedChecksum) {
    //   console.log("Checksums don't match.");
    //   throw new Error("Checksums do not match, cannot verify timer!");
    // }
  }

  // Add a stored time to mark the beginning
  storedTimes.unshift({
    duration: 0,
    manuallyAdded: false,
    startTime: timerStartTime,
  });

  return {
    timerUID: uid,
    runtime: runtimeSeconds,
    startTime: timerStartTime,
    active,
    storedTimes,
  };
}

export interface TimerStatistics {
  numDevices: number;
  totalDeviceDays: number;
  averageDaysPostExpiration: number;
  percentRemovedBeforeExpiration: string;
}

export function generateTimerStatistics(
  timers: ITimerPopulated[],
): TimerStatistics {
  const numDevices = timers.length;
  let totalDeviceDays = 0;
  let cumulativeDaysPostExpiration = 0;
  let numRemovedBeforeExpiration = 0;

  timers.forEach((timer) => {
    const deviceDay = secondsToDays(timer.totalRuntime, "up");
    const expirationDays = getExpirationDays(timer.deviceType);

    totalDeviceDays += deviceDay;
    cumulativeDaysPostExpiration += getPostExpiration(
      expirationDays,
      deviceDay,
    );
    if (deviceDay < expirationDays) {
      numRemovedBeforeExpiration++;
    }
  });

  const averageDaysPostExpiration = numDevices > 0
    ? cumulativeDaysPostExpiration / numDevices
    : 0;

  const averageRemovedBeforeExpiration = numDevices > 0
    ? (numRemovedBeforeExpiration / numDevices) * 100
    : 0;

  const formattedAverageRemovedBeforeExpiration =
    formatAverageRemovedBeforeExpiration(averageRemovedBeforeExpiration);

  return {
    numDevices,
    totalDeviceDays,
    averageDaysPostExpiration: Math.round(averageDaysPostExpiration * 100) /
      100,
    percentRemovedBeforeExpiration: `${
      Math.round(formattedAverageRemovedBeforeExpiration * 100) / 100
    }%`,
  };
}

/**
 * Handles the following rules:
 *
 * 1. If percentage is 100% or 0%, then no decimal places shown
 * 2. If percentage has and ones and tens digits (100> x >9), percentage should be be limited to 1 decimal place and rounded up
 * 3. If percentage has and only a ones digits (10 > x >/= 0), percentage should be be limited to 2 decimal place and rounded up
 */
export function formatAverageRemovedBeforeExpiration(
  averageRemovedBeforeExpiration: number,
) {
  let returnAverageRemovedBeforeExpiration = averageRemovedBeforeExpiration;

  const averageRemovedBeforeExpirationString = averageRemovedBeforeExpiration
    .toString();

  if (averageRemovedBeforeExpirationString.includes(".")) {
    const splits = averageRemovedBeforeExpirationString.split(".");
    if (splits[0].length === 2) {
      returnAverageRemovedBeforeExpiration =
        Math.round(averageRemovedBeforeExpiration * 10) / 10;
    } else {
      returnAverageRemovedBeforeExpiration =
        Math.round(averageRemovedBeforeExpiration * 100) / 100;
    }
  }

  return returnAverageRemovedBeforeExpiration;
}
