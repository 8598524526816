import {
  dayjs,
  HEADER_DATE_FORMAT_TEMPLATE,
  HEADER_DATE_MOBILE_FORMAT_TEMPLATE,
} from "@dock-technologies/common";
import { isMobile } from "./mobile";

export function getCurrentDateTimeString() {
  if (isMobile()) {
    return dayjs().format(HEADER_DATE_MOBILE_FORMAT_TEMPLATE);
  } else {
    return dayjs().format(HEADER_DATE_FORMAT_TEMPLATE);
  }
}
