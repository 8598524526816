import { useCallback, useContext, useState } from "react";
import { AuthContext } from "../providers/AuthProvider";
import Button from "../components/Button";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import Modal from "../components/Modal";
import { api } from "../api";
import SignedOutHeader from "../components/SignedOutHeader";
import PasswordInput from "../components/PasswordInput";

export default function Login() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState<Record<string, string>>({});
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");

  const { onLogin, token } = useContext(AuthContext);

  const associateTimerId = searchParams.get("associateTimerId");

  const onEmailChanged = useCallback(
    (newEmail: string) => {
      delete error["email"];
      delete error["password"];
      setError({ ...error });
      setEmail(newEmail);
    },
    [error],
  );

  const onPasswordChanged = useCallback(
    (newP: string) => {
      delete error["password"];
      setError({ ...error });
      setPassword(newP);
    },
    [error],
  );

  const onSignInClicked = useCallback(() => {
    if (!email) {
      error["email"] = "Email is required";
      setError({ ...error });
      return;
    }
    if (!password) {
      error["password"] = "Password is required";
      setError({ ...error });
      return;
    }

    onLogin({ email, password, associateTimerId })
      .then((res) => {
        if ("code" in res && res.code !== 200) {
          error["password"] = "Incorrect email or password. Try again or click FORGOT PASSWORD to reset it";
          setError({ ...error });
        } else {
          navigate("/timebands/active");
        }
      })
      .catch((err) => {
        console.error(err);
        error["password"] = "Incorrect email or password. Try again or click FORGOT PASSWORD to reset it";
        setError({ ...error });
        return;
      });
  }, [associateTimerId, email, error, navigate, onLogin, password]);

  const onForgotPasswordClicked = useCallback(() => {
    setShowForgotPassword(true);
  }, []);
  const onCloseForgotPasswordModal = useCallback(() => {
    setShowForgotPassword(false);
    setForgotPasswordEmail("");
  }, []);

  const onSubmitForgotPassword = useCallback(() => {
    return api
      .fetch("/user/api=forgotPassword?moduleType=CVC", {
        method: "POST",
        body: JSON.stringify({ email: forgotPasswordEmail }),
      })
      .then(() => {
        setShowForgotPassword(false);
        setForgotPasswordEmail("");
      });
  }, [forgotPasswordEmail]);

  if (token) {
    return <Navigate to="/timebands/active" />;
  }

  return (
    <div className="mb-32 mt-16 flex flex-col">
      <SignedOutHeader />
      <div className="mt-5 flex flex-col rounded-xl bg-white p-8 shadow-md sm:mx-[33%]">
        <div className="flex-1 text-center">
          <h2 className="text-wb text-primary-900">WELCOME BACK!</h2>
        </div>
        <div className="mt-5 flex-1">
          <div className="flex flex-col">
            <div className="flex-1">
              <label className="font-bold text-primary-700">EMAIL ADDRESS</label>
            </div>
            <div className="flex-1">
              <input
                className="w-full border border-primary-700 px-2 py-1"
                type="email"
                value={email}
                onChange={(e) => onEmailChanged(e.target.value)}
              />
            </div>
            {error["email"] && (
              <p className="mt-2 text-sm text-error-500" id="email-error">
                {error["email"]}
              </p>
            )}
          </div>
        </div>
        <div className="mt-5 flex-1">
          <div className="flex flex-col">
            <PasswordInput label="PASSWORD" password={password} onPasswordChanged={onPasswordChanged} />
            {error["password"] && (
              <p className="mt-2 text-sm text-error-500" id="password-error">
                {error["password"]}
              </p>
            )}
            <div className="mt-2 flex-1 text-right">
              <label onClick={onForgotPasswordClicked} className="font-bold text-primary-200">
                FORGOT PASSWORD?
              </label>
            </div>
          </div>
        </div>
        <div className="mt-5 flex-1">
          <Button
            className="text-bold w-full bg-primary-500 text-white hover:bg-primary-700"
            text="SIGN IN"
            onClick={onSignInClicked}
          />
        </div>
      </div>
      <Modal
        isOpen={showForgotPassword}
        onCloseClicked={onCloseForgotPasswordModal}
        onCancelClicked={onCloseForgotPasswordModal}
        onConfirmClicked={onSubmitForgotPassword}
        confirmMessage="Submit"
        cancelMessage="Cancel"
        title="Forgot Password"
        message="Please enter the email associated with your account to receive instructions on how to reset password."
      >
        <div className="flex flex-col">
          <div className="mt-2 flex-1">
            <input
              className="w-full border border-primary-700 px-2 py-1"
              type="email"
              value={forgotPasswordEmail}
              placeholder="Email Address"
              onChange={(e) => setForgotPasswordEmail(e.target.value)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
