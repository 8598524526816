import {
  ExpirationState,
  ITimerPopulated,
  RuntimeDisplay,
  getDeviceDays,
  getExpirationDays,
  getExpirationState,
} from "@dock-technologies/common";
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ColumnDefinition } from "../types";
import { formatTimerLocation, formatUserInitials } from "../utilities/format";
import { EXPIRATION_STATE_BG_COLOR_MAP, EXPIRATION_STATE_BORDER_COLOR_MAP } from "../utilities/colors";
import Button from "./Button";
import { MISSING_DEVICE_TYPE_STRING } from "../utilities/constants";
import RuntimeText from "./RuntimeText";
import { AuthContext } from "../providers/AuthProvider";
import { isDesktop, isMobile } from "../utilities/mobile";

type MappedTimer = ReturnType<typeof mapTimersToTableRows>[0];

export function mapTimersToTableRows(timers: ITimerPopulated[]) {
  return timers.map((timer) => {
    const storedTimesLength = timer.storedTimes.length;
    const { createdBy } = timer;
    return {
      _id: timer._id,
      runtime: timer.totalRuntime,
      type: timer.deviceType,
      id: timer.dockTimerID,
      steps: storedTimesLength,
      lastStep: storedTimesLength > 0 ? timer.storedTimes[storedTimesLength - 1].step : "N/A",
      deviceDay: getDeviceDays(timer),
      location: formatTimerLocation(timer.locationPrimary, timer.locationSecondary),
      user: createdBy ? formatUserInitials(createdBy) : "N/A",
      action: "",
      expirationState: getExpirationState(timer.deviceType, timer.totalRuntime, timer.active, timer.archived),
      pendingFinalization: timer.pendingFinalization,
    };
  });
}

export interface TimebandTableProps {
  timers: ITimerPopulated[];
  runtimeDisplay: RuntimeDisplay;
}

export default function TimebandActiveTable(props: TimebandTableProps) {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [timers, setTimers] = useState([]);

  useEffect(() => {
    setTimers([...props.timers]);
  }, [props.timers]);

  const navigateToTimerDetail = useCallback(
    (timerId: string) => {
      navigate(`/timebands/view/${timerId}`);
    },
    [navigate],
  );

  // const onRemoveTimerClicked = useCallback(
  //   (data: MappedTimer) => {
  //     api.fetch(`/timers/${data._id}/delete`, { method: "DELETE" }).then(() => {
  //       const timerIndex = timers.findIndex((t) => t._id === data._id);
  //       if (timerIndex !== -1) {
  //         timers.splice(timerIndex, 1);
  //         setTimers([...timers]);
  //       }
  //     });
  //   },
  //   [timers],
  // );

  const COLUMN_DEFINITIONS: Record<string, ColumnDefinition<MappedTimer>> = useMemo(() => {
    return {
      _id: {
        render: () => {
          return null;
        },
      },
      runtime: {
        render: (renderProps) => {
          return (
            <div
              className={`${EXPIRATION_STATE_BG_COLOR_MAP[renderProps.row.expirationState]} ${
                EXPIRATION_STATE_BORDER_COLOR_MAP[renderProps.row.expirationState]
              } mr-2 cursor-pointer border-2 p-2 text-white`}
              onClick={() => navigate(`/timebands/view/${renderProps.row._id}`)}
            >
              <RuntimeText runtime={parseInt(renderProps.value)} display={props.runtimeDisplay} short />
            </div>
          );
        },
      },
      type: {
        render: (props) => {
          if (!props.value) {
            return (
              <label className="cursor-pointer font-bold" onClick={() => navigateToTimerDetail(props.row._id)}>
                {MISSING_DEVICE_TYPE_STRING}
              </label>
            );
          } else {
            return (
              <label className="cursor-pointer" onClick={() => navigateToTimerDetail(props.row._id)}>
                {props.value}
              </label>
            );
          }
        },
      },
      id: {},
      steps: {},
      lastStep: {
        render: ({ value }) => {
          if (isMobile()) {
            return null;
          } else {
            return <span>{value}</span>;
          }
        },
      },
      deviceDay: {
        render: (props) => {
          const deviceDay = props.row.deviceDay;
          const expirationDays = getExpirationDays(props.row.type);
          return (
            <label className="cursor-pointer font-bold" onClick={() => navigateToTimerDetail(props.row._id)}>
              {deviceDay} <span className="font-normal">/ {expirationDays}</span>
            </label>
          );
        },
      },
      location: {
        render: (props) => {
          if (props.value === "MISSING") {
            return (
              <label className="cursor-pointer font-bold" onClick={() => navigateToTimerDetail(props.row._id)}>
                {props.value}
              </label>
            );
          } else {
            return (
              <label className="cursor-pointer" onClick={() => navigateToTimerDetail(props.row._id)}>
                {props.value}
              </label>
            );
          }
        },
      },
      user: {
        render: ({ value }) => {
          if (isMobile()) {
            return null;
          } else {
            return <span>{value}</span>;
          }
        },
      },
      action: {
        render: ({ row }) => {
          if (isMobile()) {
            return null;
          }
          if (row.expirationState === ExpirationState.PAST_EXPIRATION) {
            return (
              <Button
                text="REMOVE"
                className="bg-error-200 hover:bg-error-500"
                onClick={() => navigateToTimerDetail(row._id)}
              />
            );
          } else if (row.pendingFinalization) {
            return (
              <Button
                text="SENT"
                className="bg-neutral-500 text-white hover:bg-neutral-900"
                onClick={() => navigateToTimerDetail(row._id)}
              />
            );
          } else if ((user.isAdmin || user.isSuperAdmin) && row.expirationState === ExpirationState.NEED_FINALIZATION) {
            return (
              <Button
                text="FINISH"
                className="bg-neutral-500 text-white hover:bg-neutral-900"
                onClick={() => navigateToTimerDetail(row._id)}
              />
            );
          } else {
            return null;
          }
        },
      },
      expirationState: {
        render: () => {
          return null;
        },
      },
      pendingFinalization: {
        render: () => {
          return null;
        },
      },
    };
  }, [navigate, navigateToTimerDetail, props.runtimeDisplay, user.isAdmin, user.isSuperAdmin]);

  const rows = useMemo(() => {
    return mapTimersToTableRows(timers);
  }, [timers]);

  return (
    <div className="overflow-x-auto font-inter">
      <table className="w-full table-auto border-spacing-2 overflow-scroll text-left">
        <thead>
          <tr className="text-xs sm:text-th">
            <th>RUN TIME</th>
            <th>TYPE</th>
            <th>ID</th>
            <th>STEPS</th>
            {isDesktop() && <th>LAST STEP</th>}
            <th>DEV DAY</th>
            <th>LOCATION</th>
            {isDesktop() && <th>USER</th>}
            {isDesktop() && <th>ACTION</th>}
          </tr>
        </thead>
        <tbody className="text-xs sm:text-sm">
          <tr className="h-4"></tr>
          {rows.map((row, idx) => (
            <Fragment key={idx}>
              <tr className="h-2"></tr>
              <tr
                key={idx}
                className={`${EXPIRATION_STATE_BORDER_COLOR_MAP[row.expirationState]} cursor-pointer border-2`}
                onClick={() => navigate(`/timebands/view/${row._id}`)}
              >
                {Object.entries(row)
                  .map(([key, value], idx2) => {
                    const renderVal =
                      COLUMN_DEFINITIONS[key] && COLUMN_DEFINITIONS[key].render ? (
                        COLUMN_DEFINITIONS[key].render({ key, value, row, index: idx2 })
                      ) : (
                        <span onClick={() => navigate(`/timebands/view/${row._id}`)}>{value}</span>
                      );
                    // A null `renderVal` means we dont want to render the value for that column
                    if (renderVal) {
                      return (
                        <td className={`p-0`} key={idx2}>
                          {renderVal}
                        </td>
                      );
                    } else {
                      return null;
                    }
                  })
                  .filter((v) => v != null)}
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}
