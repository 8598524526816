import { IUser } from "@dock-technologies/common";
import UserIcon from "./UserIcon";
import { formatUserInitials } from "../utilities/format";
import { Menu } from "@headlessui/react";

export interface NavbarUserProps {
  loggedInUser?: IUser;
  onLogout: () => void;
}

export default function NavbarUser(props: NavbarUserProps) {
  if (!props.loggedInUser) {
    return null;
  }

  return (
    <Menu as="div" className="relative inline-block text-left z-10">
      <Menu.Button>
        <UserIcon className="text-white" initials={formatUserInitials(props.loggedInUser)} />
      </Menu.Button>
      <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
        <div className="p-1">
          <Menu.Item>
            {({ active }) => (
              <button
                className={`${
                  active ? "bg-primary-500 text-white" : "text-black"
                } group flex w-full items-center rounded-md px-2 py-2`}
                onClick={props.onLogout}
              >
                Logout
              </button>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    </Menu>
  );
}
