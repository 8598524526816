import {
  HISTORY_DATE_FORMAT,
  ITimerPopulated,
  RuntimeDisplay,
  dayjs,
  getExpirationDays,
  getPostExpiration,
  secondsToDays,
} from "@dock-technologies/common";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ColumnDefinition } from "../types";
import { formatUserInitials } from "../utilities/format";
import { MISSING_DEVICE_TYPE_STRING } from "../utilities/constants";
import RuntimeText from "./RuntimeText";

export interface TimebandHistoryTableProps {
  timers: ITimerPopulated[];
  runtimeDisplay: RuntimeDisplay;
}

type MappedTimer = ReturnType<typeof mapTimersToTableRows>[0];

export function mapTimersToTableRows(timers: ITimerPopulated[]) {
  return timers.map((timer) => {
    const storedTimesLength = timer.storedTimes.length;
    const notesLength = (timer.notes ?? []).length;
    const { createdBy } = timer;

    const expirationDays = getExpirationDays(timer.deviceType);
    const deviceDay = secondsToDays(timer.totalRuntime, "up");
    const postExp = getPostExpiration(expirationDays, deviceDay);

    return {
      _id: timer._id,
      runtime: timer.totalRuntime,
      startDate: timer.startTime,
      type: timer.deviceType,
      id: timer.dockTimerID,
      steps: storedTimesLength,
      notes: notesLength,
      deviceDay,
      postExp,
      user: createdBy ? formatUserInitials(createdBy) : "N/A",
    };
  });
}

export default function TimebandHistoryTable(props: TimebandHistoryTableProps) {
  const navigate = useNavigate();
  const [timers, setTimers] = useState([]);

  useEffect(() => {
    setTimers([...props.timers]);
  }, [props.timers]);

  const COLUMN_DEFINITIONS: Record<string, ColumnDefinition<MappedTimer>> = useMemo(() => {
    return {
      _id: {
        render: () => {
          return null;
        },
      },
      runtime: {
        render: (renderProps) => {
          return (
            <div
              className={`bg-neutral-900 border-neutral-900 text-white p-2 border-2 cursor-pointer mr-2`}
              onClick={() => navigate(`/timebands/view/${renderProps.row._id}`)}
            >
              <RuntimeText runtime={parseInt(renderProps.value)} display={props.runtimeDisplay} short />
            </div>
          );
        },
      },
      startDate: {
        render: ({ value }) => {
          return <label>{dayjs(value).format(HISTORY_DATE_FORMAT)}</label>;
        },
      },
      type: {
        render: (props) => {
          if (!props.value) {
            return (
              <label className="font-bold cursor-pointer" onClick={() => navigate(`/timebands/view/${props.row._id}`)}>
                {MISSING_DEVICE_TYPE_STRING}
              </label>
            );
          } else {
            return (
              <label className="cursor-pointer" onClick={() => navigate(`/timebands/view/${props.row._id}`)}>
                {props.value}
              </label>
            );
          }
        },
      },
      id: {},
      steps: {},
      lastStep: {},
      deviceDay: {
        render: (props) => {
          const deviceDay = props.row.deviceDay;
          const expirationDays = getExpirationDays(props.row.type);
          return (
            <label className="font-bold cursor-pointer" onClick={() => navigate(`/timebands/view/${props.row._id}`)}>
              {deviceDay} <span className="font-normal">/ {expirationDays}</span>
            </label>
          );
        },
      },
      postExp: {
        render: ({ value }) => {
          const numVal = parseFloat(value);
          const textColor = numVal > 0 ? "text-error-500" : numVal < -1 ? "text-success-500" : "text-warning-500";
          return (
            <label className={`${textColor}`}>
              {numVal > 0 ? "+" : ""}
              {value}
            </label>
          );
        },
      },
      user: {},
    };
  }, [navigate, props.runtimeDisplay]);

  const rows = useMemo(() => {
    return mapTimersToTableRows(timers);
  }, [timers]);

  return (
    <div className="font-inter overflow-x-auto">
      <table className="table-auto w-full text-left border-spacing-2 overflow-scroll">
        <thead>
          <tr className="text-xs sm:text-th">
            <th>RUN TIME</th>
            <th>START DATE</th>
            <th>TYPE</th>
            <th>ID</th>
            <th>STEPS</th>
            <th>NOTES</th>
            <th>DEV DAY</th>
            <th>POST EXP</th>
            <th>USER</th>
          </tr>
        </thead>
        <tbody className="text-xss sm:text-sm">
          <tr className="h-4"></tr>
          {rows.map((row, idx) => (
            <Fragment key={idx}>
              <tr className="h-2"></tr>
              <tr key={idx} className={`border-neutral-900 cursor-pointer border-2`}>
                {Object.entries(row)
                  .map(([key, value], idx2) => {
                    const renderVal =
                      COLUMN_DEFINITIONS[key] && COLUMN_DEFINITIONS[key].render ? (
                        COLUMN_DEFINITIONS[key].render({ key, value, row, index: idx })
                      ) : (
                        <span onClick={() => navigate(`/timebands/view/${row._id}`)}>{value}</span>
                      );
                    // A null `renderVal` means we dont want to render the value for that column
                    if (renderVal) {
                      return (
                        <td className={`p-0`} key={idx2}>
                          {renderVal}
                        </td>
                      );
                    } else {
                      return null;
                    }
                  })
                  .filter((v) => v != null)}
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}
