import { useNavigate, useParams, Navigate } from "react-router-dom";
import debounce from "lodash.debounce";

import SummaryRow from "../components/SummaryRow";
import { useCallback, useContext, useEffect, useState } from "react";
import { api, fetchTimerDetail } from "../api";
import {
  DeepPartial,
  ExpirationState,
  ITimerPopulated,
  RuntimeDisplay,
  StoredTimeStep,
  UpdateTimerRequestBody,
} from "@dock-technologies/common";
import TimerDetail from "../components/TimerDetail";
import Button from "../components/Button";
import RuntimeDisplayToggle from "../components/RuntimeDisplayToggle";
import { SUMMARY_REFRESH_INTERVAL_MS, TIMEBAND_REFRESH_INTERVAL_MS } from "../utilities/refresh";
import Modal from "../components/Modal";
import TimebandActionButtons from "../components/TimebandActionButtons";
import { AuthContext } from "../providers/AuthProvider";
import { isDesktop, isMobile } from "../utilities/mobile";
import Sidebar from "../components/Sidebar";

const MISSING_DISCONTINUED_TITLE = "Missing Discontinued Step!";
const MISSING_DISCONTINUED_MESSAGE =
  'You cannot close this timer without adding a Discontinued step. Please click "Add" to automatically add the step.';

const PROMPT_STOP_TIMER_TITLE = "Stop Timer?";
const PROMPT_STOP_TITLE_MESSAGE = "You added a Discontinued step. Would you like to stop the timer?";

const DISCONTINUED_STEP_WARNING_MESSAGE = 'The "Discontinued" step must be removed before a timer can be re-activated';

let fetchSummaryInterval: ReturnType<typeof setInterval> | null = null;
let fetchTimerInterval: ReturnType<typeof setInterval> | null = null;

export default function TimebandView() {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { timerId } = useParams();
  const [timer, setTimer] = useState<ITimerPopulated | null>(null);
  const [runtimeDisplay, setRuntimeDisplay] = useState(RuntimeDisplay.D_H);
  const [timersSummary, setTimersSummary] = useState<Record<ExpirationState, number> | null>(null);

  const [showMissingDiscModal, setShowMissingDiscModal] = useState(false);
  const [showStopPromptModal, setShowPromptModal] = useState(false);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");

  const [adminStepsMissing, setAdminStepsMissing] = useState(false);
  const [adminHeaderDataMissing, setAdminHeaderDataMissing] = useState(false);

  const onRuntimeDisplayChanged = useCallback((runtime: RuntimeDisplay) => {
    setRuntimeDisplay(runtime);
  }, []);

  const updateTimer = async (data: DeepPartial<UpdateTimerRequestBody>) => {
    // Adding a "Discontinued" step prompts user to "STOP TIMER" Y/N
    if (data.storedTimes && data.storedTimes.some((s) => s.step === StoredTimeStep.DISCONTINUED)) {
      setShowPromptModal(true);
    }

    return api
      .fetch(`/timers/${timerId}`, { method: "PATCH", body: JSON.stringify(data) })
      .then((res) => res.json())
      .then(() => fetchTimerDetail(timerId, user))
      .then((data) => setTimer({ ...data }));
  };

  const onTimerUpdated = useCallback(
    (data: DeepPartial<UpdateTimerRequestBody>) => {
      return updateTimer(data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const debouncedOnTimerUpdated = debounce(updateTimer, 1000);

  const onStopTimerClicked = useCallback(() => {
    const containsDiscontinuedStep = timer.storedTimes.some((t) => t.step === StoredTimeStep.DISCONTINUED);
    if (!containsDiscontinuedStep) {
      setShowMissingDiscModal(true);
    } else {
      return onTimerUpdated({ active: false, archived: false });
    }
  }, [onTimerUpdated, timer]);

  const automaticallyAddDiscontinuedStep = useCallback(() => {
    onTimerUpdated({
      storedTimes: [{ manuallyAdded: true, startTime: new Date(), step: StoredTimeStep.DISCONTINUED }],
    }).then(() => {
      setShowMissingDiscModal(false);
    });
  }, [onTimerUpdated]);

  const automaticallyStopTimer = useCallback(() => {
    onStopTimerClicked().then(() => {
      setShowPromptModal(false);
    });
  }, [onStopTimerClicked]);

  const sendToAdminModalConfirmClicked = useCallback(() => {
    // If steps missing we close modal since they still have work to do, otherwise we send to admin
    if (adminStepsMissing || adminHeaderDataMissing) {
      setShowAdminModal(false);
    } else {
      api
        .fetch(`/timers/${timerId}/send-to-administrator`, { method: "POST" })
        .then(() => {
          setShowAdminModal(false);
          return fetchTimerDetail(timerId, user);
        })
        .then((data) => setTimer({ ...data }));
    }
  }, [adminHeaderDataMissing, adminStepsMissing, timerId, user]);

  const onReactivateTimerClicked = useCallback(() => {
    const discontinuedStepExists = timer.storedTimes.some((t) => t.step && t.step === StoredTimeStep.DISCONTINUED);
    if (discontinuedStepExists) {
      setShowWarningModal(true);
      setWarningMessage(DISCONTINUED_STEP_WARNING_MESSAGE);
    } else {
      onTimerUpdated({ active: true, archived: false, pendingFinalization: false });
    }
  }, [onTimerUpdated, timer]);

  const onSendToAdministratorClicked = useCallback(() => {
    const stepsMissing = timer.storedTimes.some((t) => !t.step);
    const headerDataMissing =
      !timer.deviceType ||
      !timer.deviceTypeDirection ||
      !timer.locationPrimary ||
      !timer.locationSecondary ||
      !timer.mrn;
    setAdminHeaderDataMissing(headerDataMissing);
    setAdminStepsMissing(stepsMissing);
    setShowAdminModal(true);
  }, [timer]);

  const onFinalizeTimerClicked = useCallback(() => {
    onTimerUpdated({ archived: true, active: false, pendingFinalization: false });
  }, [onTimerUpdated]);

  const onStepDeleted = useCallback(
    (storedTimeIndex: number) => {
      const storedTimes = timer.storedTimes;
      storedTimes.splice(storedTimeIndex, 1);
      setTimer({ ...timer, storedTimes });
    },
    [timer],
  );

  async function fetchTimerSummary() {
    api
      .fetch("/timers/summary?moduleType=CVC")
      .then((res) => res.json())
      .then((data: { obj: Record<ExpirationState, number> }) => {
        setTimersSummary(data.obj);
      });
  }

  useEffect(() => {
    if (user) {
      if (fetchSummaryInterval) {
        clearInterval(fetchSummaryInterval);
        fetchSummaryInterval = null;
      }
      fetchSummaryInterval = setInterval(() => {
        fetchTimerSummary();
      }, SUMMARY_REFRESH_INTERVAL_MS);
      fetchTimerSummary();

      return () => {
        if (fetchSummaryInterval) {
          clearInterval(fetchSummaryInterval);
        }
      };
    }
  }, [user]);

  useEffect(() => {
    if (fetchTimerInterval) {
      clearInterval(fetchTimerInterval);
      fetchTimerInterval = null;
    }
    fetchTimerInterval = setInterval(() => {
      fetchTimerDetail(timerId, user).then((data) => setTimer({ ...data }));
    }, TIMEBAND_REFRESH_INTERVAL_MS);
    fetchTimerDetail(timerId, user).then((data) => setTimer({ ...data }));

    return () => {
      if (fetchTimerInterval) {
        clearInterval(fetchTimerInterval);
      }
    };
  }, [timerId, user]);

  if (!timerId) {
    return <div>Unknown Timer ID</div>;
  }
  if (!timer) {
    return null;
  }
  if (!user && timer.orgs.length > 0) {
    // We don't allow users to view a timer that already has an org associated
    return <Navigate to="/login" />;
  }

  return (
    <div className="mt-5 flex flex-col">
      <div className="flex flex-row">
        {isDesktop() && (
          <>
            <div className="flex flex-1 basis-6/12 flex-row"></div>
            <div className="flex flex-1 basis-2/12 flex-row"></div>
          </>
        )}
        <div className="ml-2 mr-2 flex flex-none basis-6/12 flex-row sm:ml-10 sm:mr-0 sm:basis-2/12">
          {isMobile() && <Sidebar />}
        </div>
        <div className="flex flex-none basis-6/12 flex-row sm:basis-2/12">
          <div className="mr-2 mt-2 flex-none text-label text-white sm:mt-3">RUNTIME</div>
          <div className="flex-1">
            <RuntimeDisplayToggle runtimeDisplay={runtimeDisplay} onRuntimeDisplayChanged={onRuntimeDisplayChanged} />
          </div>
        </div>
      </div>
      <div className="mt-4 flex-1">{timersSummary && <SummaryRow timersSummary={timersSummary} />}</div>
      <div className="flex-1 flex-row bg-white pt-3 text-center">
        {user && <Button onClick={() => navigate("/timebands/active")} text="GO TO ALL TIMERS" />}
        {!user && (
          <label>
            Please{" "}
            {/** We want to keep track of the just scanned timer for logged out user so we
                 can associate it once the user logs in */}
            <a href={`/login?associateTimerId=${timerId}`} className="text-primary-200 underline">
              sign in
            </a>{" "}
            to add timeband entry to your organization or modify this entry.
          </label>
        )}
      </div>
      <div className="flex-1 bg-white p-4">
        <TimerDetail
          user={user}
          timer={timer}
          onTimerUpdated={onTimerUpdated}
          debouncedOnTimerUpdated={debouncedOnTimerUpdated}
          runtimeDisplay={runtimeDisplay}
          onStepDeleted={onStepDeleted}
        />
      </div>
      <div className="flex-1 bg-white pb-12 pt-4 text-center">
        <TimebandActionButtons
          timer={timer}
          user={user}
          onStopTimerClicked={onStopTimerClicked}
          onReactivateTimerClicked={onReactivateTimerClicked}
          onSendToAdministratorClicked={onSendToAdministratorClicked}
          onFinalizeClicked={onFinalizeTimerClicked}
        />
      </div>
      <Modal
        isOpen={showMissingDiscModal}
        onCloseClicked={() => setShowMissingDiscModal(false)}
        onCancelClicked={() => setShowMissingDiscModal(false)}
        onConfirmClicked={automaticallyAddDiscontinuedStep}
        confirmMessage="Add"
        cancelMessage="Cancel"
        title={MISSING_DISCONTINUED_TITLE}
        message={MISSING_DISCONTINUED_MESSAGE}
      />
      <Modal
        isOpen={showStopPromptModal}
        onCloseClicked={() => setShowPromptModal(false)}
        onCancelClicked={() => setShowPromptModal(false)}
        onConfirmClicked={automaticallyStopTimer}
        confirmMessage="Yes"
        cancelMessage="No"
        title={PROMPT_STOP_TIMER_TITLE}
        message={PROMPT_STOP_TITLE_MESSAGE}
      />
      <Modal
        isOpen={showAdminModal}
        onCloseClicked={() => setShowAdminModal(false)}
        onCancelClicked={() => setShowAdminModal(false)}
        onConfirmClicked={sendToAdminModalConfirmClicked}
        confirmMessage={!adminStepsMissing && !adminHeaderDataMissing ? "Yes" : "OK"}
        cancelMessage={!adminStepsMissing && !adminHeaderDataMissing ? "No" : undefined}
        title={"Send to Administrator"}
        message={
          !adminStepsMissing
            ? !adminHeaderDataMissing
              ? "Is all documentation complete?"
              : "Please fill out all header info before requesting finalization."
            : "Please select a STEP for each timestamp."
        }
      />
      <Modal
        isOpen={showWarningModal}
        onCloseClicked={() => setShowWarningModal(false)}
        onCancelClicked={() => setShowWarningModal(false)}
        onConfirmClicked={() => setShowWarningModal(false)}
        confirmMessage={"OK"}
        title={"Warning!"}
        message={warningMessage}
      />
    </div>
  );
}
